import React, { useState, useEffect } from 'react';
import { AttributeControlType } from '../../../Utils/Constant';
import SelectDropdown from '../selectDropdown';
import { Row } from "react-bootstrap";
import InputWithLabel from '../InputWithLabels';

const ProductAttributes = ({ ...props }) => {

    const { data = [], onAttributeChange, disabledattributeId = [] } = props;

    const [attributeData, setAttributeData] = useState(data);

    const handleOnChangeTextbox = (Id, Value) => {
        let allAttributeData = Object.assign([], attributeData);
        let textboxAttributeData = allAttributeData.find(x => x.Id === Id);

        textboxAttributeData.DefaultValue = Value;
        textboxAttributeData.Values = Value;

        allAttributeData = [...allAttributeData.filter(x => x.Id !== Id), textboxAttributeData];

        allAttributeData = allAttributeData.sort(function (a, b) {
            return (a.Id - b.Id);
        });

        if (onAttributeChange) {
            onAttributeChange(allAttributeData);
        }
    }

    const handleOnChangeDropdown = (Id, Item) => {

        let allAttributeData = Object.assign([], attributeData);
        let dropdownAttributeData = allAttributeData.find(x => x.Id === Id);

        dropdownAttributeData.Values.map((e, i) => {
            if (e.Id === Item.value) {
                dropdownAttributeData.Values[i].IsPreSelected = true;
            }
            else {
                dropdownAttributeData.Values[i].IsPreSelected = false;
            }
        });

        allAttributeData = [...allAttributeData.filter(x => x.Id !== Id), dropdownAttributeData];

        allAttributeData = allAttributeData.sort(function (a, b) {
            return (a.Id - b.Id);
        });

        if (onAttributeChange) {
            onAttributeChange(allAttributeData);
        }
    }

    const handleOnChangeRadioButton = (Id, Item) => {

        let allAttributeData = Object.assign([], attributeData);
        let radioButtonAttributeData = allAttributeData.filter(x => x.Id == Id)[0];

        radioButtonAttributeData.Values.map((e, i) => {
            if (e.Id == Item.Id) {
                radioButtonAttributeData.Values[i].IsPreSelected = true;
            }
            else {
                radioButtonAttributeData.Values[i].IsPreSelected = false;
            }
        });

        allAttributeData = [...allAttributeData.filter(x => x.Id != Id), radioButtonAttributeData];

        allAttributeData = allAttributeData.sort(function (a, b) {
            return (a.Id - b.Id);
        });

        if (onAttributeChange) {
            onAttributeChange(allAttributeData);
        }
    }

    const handleOnChangeCheckBox = (Id, Item, IsChecked) => {

        let allAttributeData = Object.assign([], attributeData);
        let checkboxAttributeData = allAttributeData.filter(x => x.Id == Id)[0];

        checkboxAttributeData.Values.map((e, i) => {
            if (e.Id == Item.Id) {
                checkboxAttributeData.Values[i].IsPreSelected = IsChecked;
            }
        });

        allAttributeData = [...allAttributeData.filter(x => x.Id != Id), checkboxAttributeData];

        allAttributeData = allAttributeData.sort(function (a, b) {
            return (a.Id - b.Id);
        });

        if (onAttributeChange) {
            onAttributeChange(allAttributeData);
        }
    }

    useEffect(() => {
        setAttributeData(data);
    }, [data]);

    return (
        attributeData?.filter(x => !disabledattributeId.includes(x.Id))?.map((item, index) => {
            return (
                <div key={`product-attributes-${index}`}>
                    {
                        item.AttributeControlType === AttributeControlType.Dropdown &&
                        <Row className="mt-2">
                            <div className="col-md-8 form-options mb-3">
                                <SelectDropdown
                                    optionValueProp="Id"
                                    optionLabelProp="Name"
                                    data={item?.Values}
                                    value={item.Values.filter(x => x.IsPreSelected == true).length > 0 ? {
                                        label: item.Values.filter(x => x.IsPreSelected == true)[0]?.Name,
                                        value: item.Values.filter(x => x.IsPreSelected == true)[0]?.Id,
                                    } : undefined}
                                    label={item?.Name}
                                    onChange={(value) => {
                                        handleOnChangeDropdown(item?.Id, value)
                                    }} />
                            </div>
                        </Row>
                    }
                    {
                        item.AttributeControlType === AttributeControlType.TextBox &&
                        <Row className="mt-3">
                            <div className="col-md-8 form-options">
                                <InputWithLabel
                                    label={item?.Name}
                                    value={item?.DefaultValue}
                                    onChange={(value) => {
                                        handleOnChangeTextbox(item?.Id, value)
                                    }} />
                            </div>
                        </Row>
                    }
                    {
                        item.AttributeControlType === AttributeControlType.NumericTextBox &&
                        <Row className="mt-3">
                            <div className="col-md-8 form-options">
                                <InputWithLabel
                                    type="number"
                                    label={item?.Name}
                                    value={item?.DefaultValue}
                                    onChange={(value) => {
                                        handleOnChangeTextbox(item?.Id, value)
                                    }} />
                            </div>
                        </Row>
                    }
                    {
                        item.AttributeControlType == AttributeControlType.RadioButton &&
                        <Row className="mt-3">
                            <p>{item?.Name}</p>
                        </Row>
                    }
                    {
                        item.AttributeControlType == AttributeControlType.CheckBoxes &&
                        <Row className="mt-3">
                            <p>{item?.Name}</p>
                        </Row>
                    }
                </div>
            )
        })
    )
}

export default ProductAttributes;