import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import Slider from "react-slick";
import HeadingDescription from "../SectionHeadingDescription";
import { ReactComponent as PreviousDark } from "../../../Assests/Images/Zaraye/previous.svg";
import { ReactComponent as NextDark } from "../../../Assests/Images/Zaraye/next.svg";
import { useSelector } from 'react-redux';
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./index.css";

const OurCustomersSlider = () => {
  const allTestimonial = useSelector(state => state.MarketPlaceReducer.testimonialItems);
  const { t } = useContext(LocalizationContext);

  const settings = {
    dots: false,
    // infinite: true,
    // autoplay: true,
    speed: 1500,
    autoplaySpeed: 2500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <NextDark />,
    prevArrow: <PreviousDark />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
            slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <>

      {allTestimonial?.length > 0 ? (
        <div className="our-customer-section">
          <div className="container">
            <div className='row d-flex justify-content-between heading-bb'>
              <div className='col-md-8 ps-0 pe-0'>
                <HeadingDescription
                  headingText={t("zaraye.marketplace.whatourcustomersay.text")}
                  descriptionOne={t("zaraye.marketplace.whatourcustomersay.description")}
                />
              </div>
              <div className="col-md-4 pe-0 text-end">
                <Link to="" className='view-all resp-view-all'>
                  VIEW TESTIMONIALS
                </Link>
              </div>
            </div>
            <div className="slider-overlay"> </div>
            <div className="row">
              <div className="client-slider selling-products-slider">
                <Slider {...settings}>
                  {allTestimonial?.map((item, index) => (
                    <div key={`client-testimonial-${index}`} className="client-parent">
                      <div className="client-message">
                        <p className="mb-0">{item?.Description}</p>
                      </div>
                      <div className="client-name-img d-flex align-items-center flex-column flex-lg-row mt-4">
                        <div className="client-img">
                          <img src={item?.Picture} alt={item?.Title} />
                        </div>
                        <div className="client-detail d-flex flex-wrap flex-column ms-0 ms-lg-3">
                          <h2>{item?.Title}</h2>
                          <span className="client-designation d-block">
                            {item?.Designation}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="slider-end-overlay"> </div>
          </div>
        </div>
      )

        : (
          ''
        )}
    </>
  );
};

export default OurCustomersSlider;
