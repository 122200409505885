import React from "react";
import logo from "../../../Assests/Images/Global/footer-logo.png";
import playStoreLogo from "../../../Assests/Images/Common/googleplay.png";
import iosLogo from "../../../Assests/Images/Common/appstore.png";
import { Link } from "react-router-dom";
import "./index.css";

const Footer = () => {
  return (
    <div className="footer-section global-footer">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-5 col-lg-4 col-xl-5">
            <img src={logo} alt={logo} className="global-footer-logo" />
            <p className="mt-3 mt-md-5">
              Copyright © 2021-2023 Zaraye Digital Services. All rights reserved
            </p>
          </div>
          <div className="col-10 col-md-6 col-lg-6 col-xl-4 d-flex align-items-center justify-content-end flex-wrap global-footer-right">
            <div className="zaraye-market">
              <h4>
                <Link to="/"> Zaraye</Link>
              </h4>
              <h4>
                <Link to="/solution"> Solutions</Link>
              </h4>
              <h4>
                <Link to="/company"> Company</Link>
              </h4>
            </div>
            <div className="footer-logos">
              <Link
                to="https://play.google.com/store/apps/details?id=com.zaraye.app"
                target="_blank"
              >
                <img src={playStoreLogo} alt={playStoreLogo} className="me-4" />
              </Link>
              <Link
                to="https://apps.apple.com/app/id1608301344"
                target="_blank"
              >
                <img src={iosLogo} alt={iosLogo} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
