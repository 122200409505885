import React from "react";
import ZarayeGlobalLogo from "../../../Assests/Images/Global/zaraye-global-logo.png";
import Button from "../../Common/CustomBtn";
import Search from "../../../Assests/Images/Zaraye/search.svg"
import { Link } from "react-router-dom";
import "./style.css";

const Header = () => {

  return (
    <div className="container position-relative">
      <div className="d-flex justify-content-between align-items-center main-header">
        <Link to={'/global'}>
          <img src={ZarayeGlobalLogo} alt="Zaraye" className="zaraye-global-img" />
        </Link>
        <div className="col-md-6 d-flex align-items-center justify-content-end">
          <Button className="primary-btn header-btn cursor-pointer me-3 d-none d-md-block" text='Reach To Us Now' />
          <img src={Search} alt="Search" className="cursor-pointer px-3" />
        </div>
      </div>
    </div>
  );
};

export default Header;
