import FooterLogo from "../../../Assests/Images/Zaraye/footer-logo.svg";
import AppStore from "../../../Assests/Images/Common/appstore.png";
import PlayStore from "../../../Assests/Images/Common/googleplay.png";
import Facebook from "../../../Assests/Images/Zaraye/facebook-round.svg";
import Linkedin from "../../../Assests/Images/Zaraye/linkedin-round.svg";
import Twitter from "../../../Assests/Images/Zaraye/twitter-round.svg";
import { Link } from "react-router-dom";
import "./style.css";

const Footer = () => {
  const FooterLinks = [
    {
      heading: "Quicklinks",
      links: ["About us", "Products", "Solutions", "Brands", "Contact Us"],
    },
    {
      heading: "Categories",
      links: ["Textile", "Irons Steel", "Polymer", "Construction"],
    },
    {
      heading: "Discover More",
      links: ["Blogs", "Sitemap", "Terms", "Policy"],
    },
  ];
  return (
    <div className="footer-container">
      <div className="container">
        <div className="row d-flex justify-content-between">
          <div className="col-lg-5">
            <img src={FooterLogo} alt={FooterLogo} loading="lazy" />
            <p className="py-4">
              Discover a New Era of Efficiency and Reliability in Raw Material
              Sourcing. Connect with Trusted Suppliers, Unlock Cost Savings, and
              Streamline Your Supply Chain with Zaraye.
            </p>
            <div className="d-flex">
              <img
                src={PlayStore}
                alt={PlayStore}
                loading="lazy"
                width={182}
                className="mr-3"
              />
              <img
                src={AppStore}
                alt={AppStore}
                loading="lazy"
                width={182}
                className="ms-3"
              />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="row d-flex">
              {FooterLinks.map((section, index) => (
                <div key={index} className="col-md-4 footer-links">
                  <h5>{section.heading}</h5>
                  <ul className="list-group list-unstyled footer-links-items">
                    {section.links.map((link, linkIndex) => (
                      <li key={linkIndex}>
                        <Link className="text-decoration-none">{link}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between footer-copyright">
          <p className="mb-0">
            Copyright © 2021-2023 Zaraye Digital Services. All rights reserved
          </p>
          <div className="d-flex">
            <Link>
              <img
                src={Facebook}
                alt={Facebook}
                loading="lazy"
                className="mx-2"
              />
            </Link>
            <Link>
              <img
                src={Linkedin}
                alt={Linkedin}
                loading="lazy"
                className="mx-2"
              />
            </Link>
            <Link>
              <img
                src={Twitter}
                alt={Twitter}
                loading="lazy"
                className="mx-2"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
