import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import HeadingDescription from "../SectionHeadingDescription";
import CloseIcon from "../../../Assests/Images/Common/close-dark.svg";
import Input from "../input";
import Button from "../CustomBtn";
import { Row } from "react-bootstrap";
import "./style.css";

const QuickRequestModal = (props) => {
    const { onHide } = props;
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const handleOnClickSubmitRequest = async() => {
        
    }

  return (
    <div className="quote-modal">
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
        <Modal.Body className="p-0 main-form">
            <div className="d-flex justify-content-end modal-close">
                <img
                    src={CloseIcon}
                    alt="Close"
                    width={16}
                    className="cursor-pointer"
                    onClick={onHide}
                />
            </div>
            <Row className="justify-content-center">
                <div className="col-md-10 pt-4 pb-4">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-9 request-headings">
                            <HeadingDescription
                                headingText={
                                    "Get Your Instant Quote, Share Your Details for a Personalized Callback"
                                }
                                descriptionOne={
                                    "Experience Hassle-free Procurement. Let Us Reach Out and Provide You with Tailored Solutions"
                                }
                            />
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-9">
                            <div className="form-group">
                                <Input
                                    className={"form-control text-center"}
                                    placeholder={"Enter Your Name"}
                                    type="text"
                                    name={"name"}
                                    // value={name}
                                    onChange={(e)=> {setName(e.target.value)}}
                                />
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="form-group">
                                <Input
                                    className={"form-control text-center"}
                                    placeholder={"+92 333 1234567"}
                                    type="text"
                                    // value={phoneNumber}
                                    onChange={(e)=> {setPhoneNumber(e.target.value)}}
                                    name={"Phone Number"}
                                />
                            </div>
                        </div>
                        <div className="col-md-9">
                            <Button
                                className={"primary-btn w-100 text-center ripple"}
                                text="SUBMIT REQUEST"
                                onClick={handleOnClickSubmitRequest}
                            />
                        </div>
                    </div>
                </div>
            </Row>
        </Modal.Body>
        </Modal>
    </div>
  );
}

export default QuickRequestModal;
