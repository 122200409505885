import React, { useState, useEffect } from "react";
import Select from "react-select";

const SelectDropdown = ({ ...props }) => {
  const {
    data = [],
    label = "",
    optionValueProp = "value",
    optionLabelProp = "label",
    onChange,
    styles,
    placeholder,
    value
  } = props;

  const [options, setOptions] = useState(data);

  const handleOnChange = (item) => {
    if (onChange) {
      onChange(item);
    }
  };

  useEffect(() => {
    const onLoad = () => {
      var dropDownOptions = [];
      data?.map((item, index) => {
        dropDownOptions.push({
          value: item[optionValueProp],
          label: item[optionLabelProp],
        });
      });
      setOptions(dropDownOptions);
    };
    if (data.length > 0) {
      onLoad();
    }
  }, [data]);

  const dropdownWithLabel = {
    menu: (provided, state) => ({
      ...provided,
      borderRadius: "8px", // Add border-radius
      border: "1px solid teal",
      marginLeft: "-14px",
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: "4px 8px",
    }),

    container: (provided, state) => ({
      ...provided,
      border: "1px solid teal",
      borderRadius: "40px",
      padding: "0 10px",
      background: "#ffffff42",
      marginTop: "10px",
      fontSize: "12px",
      fontFamily: "Light",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      background: "none",
      minHeight: "42px",
      border: "0",
      height: "50px",

      svg: {
        height: "17px",
        width: "17px",
      },
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#808080",
      border: "0",
      padding: "0",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "teal" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "teal",
        color: "white",
        // border: "2px solid",
      },
    }),
  };

  return label !== "" ? (
    <div className="form-group position-relative prod-select">
      <Select
        options={options}
        onChange={handleOnChange}
        styles={dropdownWithLabel}
        placeholder={placeholder}
        value={value}
        // components={{ DropdownIndicator: () => null }}
      />
      <label>{label}</label>
    </div>
  ) : (
    <Select
      options={options}
      onChange={handleOnChange}
      styles={styles}
      placeholder={placeholder}
      value={value}
      // components={{ DropdownIndicator: () => null }}
    />
  );
};

export default SelectDropdown;
