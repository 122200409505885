import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import MobileTracking from "../../../Assests/Images/Common/mobile-tracking-screen.png";
import CustomButton from "../../../Components/Common/CustomBtn";
import "./style.css";

const RequestCompletePage = () => {
  const navigate = useNavigate();

  return (
    <Container className="thankyou-page">
      <div className="breadcrumb">
        <span className="pe-1 unactive">
          <Link to={"/"}>Home</Link>
        </span>
        <span className="pe-1 greater">
          <img src={ArrowLeft} width={14} alt="greater than" />
        </span>
        <span className="pe-1 unactive">
          <Link to={"/"}>Product</Link>
        </span>
        <span className="pe-1 greater">
          <img src={ArrowLeft} width={14} alt="greater than" />
        </span>
        <span className="pe-1 unactive">
          <Link to={"/"}>Cart</Link>
        </span>
        <span className="pe-1 greater">
          <img src={ArrowLeft} width={14} alt="greater than" />
        </span>
        <span className="activelink">Request Complete</span>
      </div>
      <Row className="justify-content-center">
        <div className="col-md-10 col-lg-8 col-xl-6 pt-0 pt-sm-4 d-flex flex-column align-items-center justify-content-center">
          <div className="text-center pb-3">
            <HeadingDescription
              headingText={
                "Requested Completed! Get Seamless Order Tracking with Zaraye Mobile App"
              }
              descriptionOne={
                "Stay Informed Every Step of the Way. Download our app and effortlessly track your orders in real-time for a seamless shopping experience."
              }
            />
          </div>
          <img
            src={MobileTracking}
            alt={MobileTracking}
            loading="lazy"
            className="pt-5 mobile-tracking"
          /> 
          <CustomButton
            className="primary-btn go-to-marketplace"
            text={"BACK TO MARKETPLACE"}
            onClick={() => navigate("/")}
          />
        </div>
      </Row>
    </Container>
  );
};

export default RequestCompletePage;
