import ContentBlockWithImage from "../../../Components/Zaraye/ContentBlockWithImage";
import LandingPagesMainTitle from "../../../Components/Zaraye/LandingPagesTitle";
import ContentBlockImage from "../../../Assests/Images/Zaraye/redefine-sourcing.png";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import BrandSlider from "../../../Components/Zaraye/BrandSlider";
import Story from "../../../Assests/Images/Zaraye/story.svg";
import TGA from "../../../Assests/Images/MarketPlace/tga.png";
import Bookme from "../../../Assests/Images/MarketPlace/bookme.png";
import Finja from "../../../Assests/Images/MarketPlace/finja.png";
import OkayKer from "../../../Assests/Images/MarketPlace/okay-ker.png";
import Abhi from "../../../Assests/Images/MarketPlace/abhi.png";
import BusCaro from "../../../Assests/Images/MarketPlace/bus-caro.png";
import CoFounderMessage from "../../../Components/Zaraye/CoFounderMessage";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import Gulahmed from "../../../Assests/Images/Zaraye/gulahmed.png";
import Amreli from "../../../Assests/Images/Zaraye/amreli.png";
import Lucky from "../../../Assests/Images/Zaraye/lucky.png";
import Alkaram from "../../../Assests/Images/Zaraye/alkaram.png";
import Diamond from "../../../Assests/Images/Zaraye/diamond.png";
import "./index.css";

const AboutPage = () => {
  const settings = {
    centerMode: true,
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear",
  };

  const networkSettings = {
    // centerMode: false,
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 2,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 1000,
    // cssEase: "linear",
  };

  const valueSettings = {
    centerMode: true,
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear",
  };

  const networkBrands = [
    {
      id: 1,
      image: TGA,
    },
    {
      id: 2,
      image: Bookme,
    },
    {
      id: 3,
      image: Finja,
    },
    {
      id: 4,
      image: OkayKer,
    },
    {
      id: 5,
      image: Abhi,
    },
    {
      id: 6,
      image: BusCaro,
    },
    {
      id: 7,
      image: TGA,
    },
    {
      id: 8,
      image: Bookme,
    },
    {
      id: 9,
      image: Finja,
    },
  ];

  const brands = [
    {
      id: 1,
      image: Story,
      description: "embrace the adventure",
    },
    {
      id: 2,
      image: Story,
      description: "embrace the adventure",
    },
    {
      id: 3,
      image: Story,
      description: "embrace the adventure",
    },
    {
      id: 4,
      image: Story,
      description: "embrace the adventure",
    },
    {
      id: 5,
      image: Story,
      description: "embrace the adventure",
    },
    {
      id: 6,
      image: Story,
      description: "embrace the adventure",
    },
    {
      id: 7,
      image: Story,
      description: "embrace the adventure",
    },
    {
      id: 8,
      image: Story,
      description: "embrace the adventure",
    },
  ];

  const value = [
    {
      id: 1,
      image: Gulahmed,
    },
    {
      id: 2,
      image: Amreli,
    },
    {
      id: 3,
      image: Lucky,
    },
    {
      id: 4,
      image: Alkaram,
    },
    {
      id: 5,
      image: Diamond,
    },
    {
      id: 6,
      image: Amreli,
    },
    {
      id: 7,
      image: Gulahmed,
    },
    {
      id: 8,
      image: Alkaram,
    },
    {
      id: 9,
      image: Lucky,
    },
    {
      id: 10,
      image: Diamond,
    },
    {
      id: 11,
      image: Amreli,
    },
    {
      id: 12,
      image: Gulahmed,
    },
    {
      id: 13,
      image: Alkaram,
    },
    {
      id: 14,
      image: Amreli,
    },
  ];

  return (
    <div className="about-pg">
      <LandingPagesMainTitle
        headingText="About Zaraye - Defining Efficient Sourcing"
        descriptionOne="Zaraye is a leading B2B platform, connecting manufacturers with suppliers across the country. We aim to digitize sourcing processes, increasing efficiency in the textile, construction, and polymers industry."
      />
      <section className="redefining-sourcing-section">
        <ContentBlockWithImage
          headingText="Zaraye - The Redefining Sourcing Excellence"
          descriptionOne="At Zaraye, we embark on a transformative journey that redefines sourcing in the textile, construction, and polymers industries. With a relentless pursuit of innovation, we unite manufacturers and suppliers, introducing efficiency and reliability to the heart of procurement."
          descriptionTwo="Our commitment to excellence drives us to elevate businesses, streamline processes, and reshape industries. Discover the power of Zaraye as we usher in a new era of sourcing excellence."
          sectionImg={ContentBlockImage}
        />
      </section>
      <section className="collaborative-network-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto text-center">
              <HeadingDescription
                headingText="Our Collaborative Network"
                descriptionOne="Step into the World of Synergy and Success. Meet the Visionaries Who Stand Hand in Hand with Zaraye, Fueling Innovation, Growth, and Collective Achievement"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid zaraye-core-values">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="about-brand-slider-section text-center">
                  <BrandSlider
                    data={networkBrands}
                    settings={networkSettings}
                    showDescriptions={false}
                  />
                </div>
              </div>
              <div className="col-md-8 mx-auto text-center">
                <HeadingDescription
                  headingText="Unveiling Zaraye's Core Values"
                  descriptionOne="Our Core Values Illuminate the Path to Excellence, Driving Innovation, Integrity, Collaboration, and Customer-Centricity at Every Step."
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 core-values-slider">
              <BrandSlider
                data={brands}
                settings={settings}
                showDescriptions={true}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="our-visionaries-section">
        <CoFounderMessage />
      </section>

      <div className="brand-section text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto trusted">
              <HeadingDescription
                headingText="Trusted Manufacturers and Brands"
                descriptionOne="Discover a curated selection of products from our esteemed network of trusted manufacturers and renowned brands"
              />
            </div>
          </div>
        </div>
        <BrandSlider
          brands={value}
          settings={valueSettings}
          showDescriptions={false}
        />
      </div>

      <GetZarayeApp />
    </div>
  );
};

export default AboutPage;
