import * as ActionTypes from '../../Actions/ActionTypes';

const initialState = {
    testimonialItems: [],
    employeeInsights: [],
    marketplaceExchangeRate: []
}

const MarketPlaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_CUSTOMER_TESTIMONIAL_ITEMS:
            return {
                ...state,
                testimonialItems: action.data
            };
        case ActionTypes.LOAD_EMPLOYEE_INSIGHTS:
            return {
                ...state,
                employeeInsights: action.data
            };
        case ActionTypes.LOAD_MARKETPLACE_EXCHANGE_RATE:
            return {
                ...state,
                marketplaceExchangeRate: action.data
            };
        default:
            return state;
    }

};

export default MarketPlaceReducer;