import { Helmet } from "react-helmet";
import { useEffect, useState } from 'react';
import CommonService from '../../../Services/Common/CommonService';
import { Container } from "react-bootstrap";
import { Link } from 'react-router-dom'
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png"
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import Button from "../../../Components/Common/CustomBtn";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import ProductsGridItem from "../../../Components/Common/ProductsGridItem";
import "./style.css"

const BrandDetailPage = ({ item }) => {

  const [brandData, setBrandData] = useState({});
console.log('brandData', brandData);
  const getBrandDetail = async (id) => {
    var response = await CommonService.GetBrandById(id);
    if (response?.success) {
      setBrandData(response?.data)
    }
  }

  useEffect(() => {
    getBrandDetail(item?.Id);
  }, [item?.Id]);

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta data-react-helmet="true" name="description" content={item?.MetaDescription ? item?.MetaDescription : ''} />
        <meta data-react-helmet="true" name="keywords" content={item?.MetaKeywords ? item?.MetaKeywords : ''} />
        <title>{item?.MetaTitle ? item?.MetaTitle : 'Zaraye - B2B Raw Material Platform'}</title>
        {
          item?.Slug &&
          <link rel="canonical" href={`https://www.react-app.zaraye.co/${item?.Title}`} />
        }
      </Helmet>
      <div className="mp-home">
        <Container>
          <div className="breadcrumb">
            <span className="pe-1 unactive">
              <Link to={"/"}>
                Home
              </Link>
            </span>
            <span className="pe-1 greater">
              <img src={ArrowLeft} width={14} alt="greater than" />
            </span>
            <span className="pe-1 unactive">
              <Link to>
                Brands
              </Link>
            </span>
            <span className="pe-1 greater">
              <img src={ArrowLeft} width={14} alt="greater than" />
            </span>
            <span className="activelink">
              {brandData?.Name}
            </span>
          </div>
        </Container>

        <div className="all-brands-main">
          <Container>
            <div className="d-flex flex-column align-items-center justify-content-center sub-header-container">
              <img src={brandData?.Picture} alt={brandData?.Name} loading="lazy" />
              <div className="row">
                <div className="col-md-9 mx-auto">
                  <p className="mt-3 text-center" dangerouslySetInnerHTML={{__html: brandData?.ShorDescription }}>
                  {/* dangerouslySetInnerHTML={{ __html: topicData?.Body }}
                    {brandData?.ShorDescription} */}
                  </p>
                </div>
              </div>
            </div>
          </Container>
          <div className="popular-products-section">
            <div className="col-md-12 d-flex flex-wrap grid-product position-relative">
              <ProductsGridItem data={brandData}/>
            </div>
          </div>
          <Container>
            <div className="row brand-detail-section justify-content-between align-items-center" 
            style={ { display: brandData?.LongDescription ? "flex" : 'none' } }
            >
              <div className="col-md-6 bd-long-desc">
                <HeadingDescription headingText={`Learn More About ${brandData?.Name}`} descriptionOne={brandData?.LongDescription} />
                <Button
                  className={"primary-btn text-center ripple"}
                  text="GET A CUSTOM QUOTE"
                />
              </div>
              <div className="col-md-5 d-flex justify-content-end align-items-center brands-details-img">
                <img src={brandData?.Picture} alt={brandData?.Name} loading="lazy" className="img-fluid" />
              </div>
            </div>
          </Container>
          <GetZarayeApp />
        </div>
      </div>
    </>
  );
}

export default BrandDetailPage;
