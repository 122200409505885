import MarketPlaceService from '../../Services/MarketPlace/MarketPlaceService';
import * as ActionTypes from '../ActionTypes';

export const fetchCustomerTestimonialItems = (pageIndex = 0, pageSize = 2147483647) => {
    return async (dispatch, getState) => {
        var response = await MarketPlaceService.GetCustomerTestimonial(pageIndex, pageSize);
        if (response.success) {
            dispatch({
                type: ActionTypes.LOAD_CUSTOMER_TESTIMONIAL_ITEMS,
                data: response.data
            });
        } else {
            dispatch({
                type: ActionTypes.LOAD_CUSTOMER_TESTIMONIAL_ITEMS,
                data: []
            });
        }
    }
}

export const fetchEmployeeInsights = (pageIndex = 0, pageSize = 2147483647) => {
    return async (dispatch, getState) => {
        var response = await MarketPlaceService.GetEmployeeInsights(pageIndex, pageSize);
        if (response.success) {
            dispatch({
                type: ActionTypes.LOAD_EMPLOYEE_INSIGHTS,
                data: response.data
            });
        } else {
            dispatch({
                type: ActionTypes.LOAD_EMPLOYEE_INSIGHTS,
                data: []
            });
        }
    }
}

export const fetchMarketplaceExchangerate = () => {
    return async (dispatch, getState) => {
        var response = await MarketPlaceService.GetMarketplaceExchangeRate();
        if (response.success) {
            dispatch({
                type: ActionTypes.LOAD_MARKETPLACE_EXCHANGE_RATE,
                data: response.data
            });
        } else {
            dispatch({
                type: ActionTypes.LOAD_MARKETPLACE_EXCHANGE_RATE,
                data: []
            });
        }
    }
}