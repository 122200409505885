import React from 'react'
import './index.css'

const HeadingDescription = ({ headingText,subHeadingText, descriptionOne, descriptionTwo }) => {
  return (
    <div>
      <h2 className='section-heading'>{headingText}</h2>
      {subHeadingText && (
        <h2 className='section-sub-heading'>{subHeadingText}</h2>
      )}
      {descriptionOne && (
        <p dangerouslySetInnerHTML={{ __html: descriptionOne }} className='section-description mt-3'></p>
      )}
      {descriptionTwo && (
        <p className='section-description mb-0'>{descriptionTwo}</p>
      )}
    </div>
  )
}

export default HeadingDescription
