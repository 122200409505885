import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import menuPolygonIcon from '../../../Assests/Images/Common/polygon.svg';
import { Link } from 'react-router-dom';
import './index.css';
import i18n from 'i18n-js';
import LocalizationContext from '../../../Utils/LocalizationContext';
import { useContext } from 'react';

const IndustryMenu = () => {

    const industryList = useSelector((state) => state.CommonReducer.industries);

    const { t } = useContext(LocalizationContext);

    const [isOpen, setIsOpen] = useState(false);

    const menuRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const closeMenu = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        }
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div ref={menuRef} className={`industry-mega-menu ${isOpen ? 'list-open' : ''}`}>
            <div onClick={toggleMenu} className="menu-button">
                {t("zaraye.marketplace.industries.text")}
                {isOpen && <span className="polygon-icon"> <img src={menuPolygonIcon} alt={menuPolygonIcon} /> </span>}
            </div>
            <div className="menu-content">
                <div className="menu-industry">
                    <ul>
                        {industryList.map((item, index) => (
                            <Link onClick={closeMenu} to={`/${item.SeName}`} key={`industry-item-${index}`}>
                                <li className='menu-industry-li'>
                                    <div className="img-div">
                                        <img src={item.Picture} alt={item.Text} className='prod-img-fix'/>
                                    </div>
                                    <h3>{item.Text}</h3>
                                </li>
                            </Link>
                        ))}
                    </ul>
                </div>

                {/* Add more columns */}
            </div>
        </div>
    );
};

export default IndustryMenu;
