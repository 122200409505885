import React, { useContext } from 'react'
import HeadingDescription from "../SectionHeadingDescription";
import CustomButton from "../CustomBtn";
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./style.css";

const Newsletter = () => {
    const { t } = useContext(LocalizationContext);

    return (
        <div className="footer-newsletter">
            <div className="container">
                <div className="row d-flex align-items-center newsletter">
                    <div className="col-lg-4 ps-3 ps-md-0">
                        <HeadingDescription
                            headingText={t("zaraye.marketplace.stayinformed.text")}
                        />
                    </div>
                    <div className="col-lg-8 pe-0 subscribe-input">
                        <div className="search-bar d-flex justify-content-between align-items-center">
                            <div className="search-field form-group">
                                <input
                                    type="email"
                                    className='form-control border-0 bg-transparent'
                                    placeholder={t("zaraye.marketplace.enteryouremailhere.placeholder")}
                                    autoComplete="off"
                                />
                            </div>
                            <CustomButton text='SUBSCRIBE NOW' className='primary-btn' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Newsletter;
