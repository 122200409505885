import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'
import { Container } from "react-bootstrap";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png"
import LandingPagesMainTitle from "../../../Components/Zaraye/LandingPagesTitle";
import { useSelector } from 'react-redux';
import Pagination from "../../../Components/Common/Pagination";
import SelectDropdown from "../../../Components/Common/selectDropdown";
import Input from "../../../Components/Common/input";
import Button from "../../../Components/Common/CustomBtn";
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./style.css"

const BrandPage = ({ item }) => {
  const allBrands = useSelector((state) => state.CommonReducer.allBrands);
  const industryList = useSelector((state) => state.CommonReducer.industries);
  const [brandName, setBrandName] = useState("");
  const [filterBrands, setFilterBrands] = useState(allBrands);
  const { t } = useContext(LocalizationContext)

  const [selectedIndustry, setSelectedIndustry] = useState(null);

  const handleSelectIndustry = (item) => {
    setSelectedIndustry(item);
  };

  const handleOnClickApply = async () => {

    const filterData =
      allBrands.filter(x => ((x?.BrandName).toLowerCase()).includes(brandName?.toLowerCase()))
        .filter(x => parseInt(x?.IndustryId) === parseInt(selectedIndustry?.value))

    setFilterBrands(filterData)
  }

  const quoteFormDropdownStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderRadius: '8px', // Add border-radius
      border: '1px solid teal',
      marginLeft: '-10px',
    }),

    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: '4px 8px',
    }),

    container: (provided, state) => ({
      ...provided,
      border: '1px solid teal',
      borderRadius: '40px',
      padding: '0 10px',
      background: '#ffffff42',
      marginTop: '10px',
      fontSize: '14px',
      fontFamily: 'Regular',
      color: '#000',
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      background: 'none',
      minHeight: '32px',
      border: '0',
      svg: {
        height: '17px',
        width: '17px',
      },
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#808080',
      border: '0',
      padding: '0',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'teal' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'teal',
        color: 'white',
        border: '2px solid',

      },
    }),
  };


  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta data-react-helmet="true" name="description" content={item?.MetaDescription ? item?.MetaDescription : ''} />
        <meta data-react-helmet="true" name="keywords" content={item?.MetaKeywords ? item?.MetaKeywords : ''} />
        <title>{item?.MetaTitle ? item?.MetaTitle : 'Zaraye - B2B Raw Material Platform'}</title>
        {
          item?.Slug &&
          <link rel="canonical" href={`http://app-react.zaraye.co/${item?.Slug}`} />
        }
      </Helmet>
      <section className="brands-page">
        <Container>
          <div className="breadcrumb">
            <span className="pe-1 unactive">
              <Link to={"/"}>
                Home
              </Link>
            </span>
            <span className="pe-1 greater">
              <img src={ArrowLeft} width={14} alt="greater than" />
            </span>
            <span className="activelink">
              {/* <Link to> */}
              Brands
              {/* </Link> */}
            </span>
          </div>
          <div className="all-brands-main">
            <LandingPagesMainTitle
              headingText={t("zaraye.marketplace.brands.main.title")}
              descriptionOne={t("zaraye.marketplace.brands.main.description")}
            />
            <div className="row brands-filters">
              <div className="col-sm-12 col-md-4 col-lg-3 mb-3 mb-md-0">
                <Input
                  className={"form-control"}
                  placeholder={"Enter Brand Name"}
                  type="text"
                  name={"name"}
                  onChange={(e) => {
                    setBrandName(e.target.value)
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mb-3 mb-md-0">
                <SelectDropdown
                  value={selectedIndustry}
                  onChange={handleSelectIndustry}
                  styles={quoteFormDropdownStyles}
                  placeholder={'Select Industry'}
                  data={industryList}
                  menuIsOpen={true}
                  optionValueProp={'Value'}
                  optionLabelProp={'Text'}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 brand-filter-btn mb-3 mb-md-0">
                <Button
                  className={"primary-btn w-100 text-center"}
                  text="APPLY FILTER"
                  onClick={handleOnClickApply}
                />
              </div>
            </div>
            <div className="d-flex flex-wrap mx-auto all-brands">
              {filterBrands?.map((item, index) => (
                <Link key={`item.brands-${index}`} to={`/${item.SeName}`} className="brand-item">
                  {/* <div className="mb-3 mb-sm-4"> */}
                    <img src={item?.Picture} alt={item?.BrandName} loading="lazy" />
                  {/* </div> */}
                </Link>
              ))}
            </div>
            <div className="pagination-box row">
              <Pagination />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default BrandPage;
