import RestClient from '../RestClient';

const GetMarketPlaceSearch = (keyword) => {
    return RestClient.Get(`marketplace/search-market-place/${keyword}`, false);
}

const AddOnlineLead = (payload) => {
    return RestClient.Post(`marketplace/add-online-lead/`, payload);
}

const GetCustomerTestimonial = (pageIndex, pageSize) => {
    return RestClient.Get(`marketplace/get-all-customer-testimonials?pageIndex=${pageIndex}&pagesize=${pageSize}`);
}

const GetEmployeeInsights = (pageIndex, pageSize) => {
    return RestClient.Get(`marketplace/get-all-employee-insights?pageIndex=${pageIndex}&pagesize=${pageSize}`);
}
const GetMarketplaceExchangeRate = () => {
    return RestClient.Get(`marketplace/get-all-marketplace-exchangerate`);
}

const exportedObject = {
    GetMarketPlaceSearch,
    AddOnlineLead,
    GetCustomerTestimonial,
    GetEmployeeInsights,
    GetMarketplaceExchangeRate
};

export default exportedObject;