import axios from 'axios';
import LocalStorage from '../Utils/LocalStorage';
import { store } from '../Store/Store';
import { showLoader, hideLoader } from '../Actions/Common/CommonAction';

const axiosInstance = (token) =>
    axios.create({
        'Content-Type': 'application/json',
        baseURL: "https://api.zortal.co/v4/",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Credentials': 'true',
        },
        withCredentials: 'true'
    });

const Get = async (host, loader = true) => {
    if (loader) {
        store.dispatch(showLoader());
    }
    var token = await LocalStorage.GetData("token");
    
    return axiosInstance(token).get(host, {withCredentials: 'true'})
        .then(response => {
            store.dispatch(hideLoader());
            return response.data;
        })
        .catch(error => {
            store.dispatch(hideLoader());
            return error;
        });
}

const Post = async (host, payload, loader = true) => {
    if (loader) {
        store.dispatch(showLoader());
    }
    var token = await LocalStorage.GetData("token");
    return axiosInstance(token).post(host, payload)
        .then(response => {
            store.dispatch(hideLoader());
            return response.data;
        })
        .catch(error => {
            store.dispatch(hideLoader());
            return error;
        });
}

const Put = async (host, payload, loader = true) => {
    if (loader) {
        store.dispatch(showLoader());
    }
    var token = await LocalStorage.GetData("token");
    return axiosInstance(token).put(host, payload)
        .then(response => {
            store.dispatch(hideLoader());
            return response.data;
        })
        .catch(error => {
            store.dispatch(hideLoader());
            return error;
        });
}

const Delete = async (host, payload, loader = true) => {
    if (loader) {
        store.dispatch(showLoader());
    }
    var token = await LocalStorage.GetData("token");
    return axiosInstance(token).delete(host, payload)
        .then(response => {
            store.dispatch(hideLoader());
            return response.data;
        })
        .catch(error => {
            store.dispatch(hideLoader());
            return error;
        });
}

const exportedObject = {
    Get,
    Post,
    Put,
    Delete
};

export default exportedObject;