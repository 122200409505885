const AttributeControlType =
{
    Dropdown: 1,
    RadioButton: 2,
    CheckBoxes: 3,
    TextBox: 4,
    NumericTextBox: 55,
    TextArea: 10,
    Datepicker: 20,
    FileUpload: 30,
    ColorSquares: 40,
    ImageSquares: 45,
    ReadonlyCheckboxes: 50
}

export { AttributeControlType };