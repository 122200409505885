import React from 'react'
import { Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './index.css'

const MarketNews = () => {
    const latestBlogs = useSelector(state => state.CommonReducer.latestBlogs);
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <Row className='blogs-main'>
            <div className="col-lg-7 col-xl-8">
                {latestBlogs?.length > 0 &&
                    <Link to={`/${latestBlogs[0]?.SeName}`} className='text-decoration-none'>
                        <Row className='blogs-main p-0'>
                            <div className="mn-thubmnail">
                                <img src={latestBlogs[0]?.Picture} alt={latestBlogs[0]?.Picture} className='w-100 img-fluid' />
                            </div>
                            <div className="mn-publish-detail">
                                <p className='mt-4 mb-2'>
                                    Published On <span className='fw-bold'> {latestBlogs[0]?.PublishedOn} </span> by <span className='fw-bold'> {latestBlogs[0]?.AutorName} </span>
                                </p>
                            </div>
                            <div className="mn-post-title">
                                <h2>{latestBlogs[0]?.Title}</h2>
                                <p>{latestBlogs[0]?.BodyOverview}</p>
                            </div>
                        </Row>
                    </Link>
                }
            </div>
            <div className="col-lg-5 col-xl-4 ps-sm-2 ps-md-3 ps-xxl-5 blogs-left">
                <h2 className='latest-article'>Trending Insights</h2>
                {latestBlogs?.map((item, index) => {
                    if (index === 0) {
                        return null;
                    } else {
                        return (<div key={`blog-item-${index}-${item?.SeName}`} className="small-news-post" >
                            <Link to={`/${item?.SeName}`} className='text-decoration-none'>
                                <div className="mn-publish-detail">
                                    <p className='mb-2'>
                                        Published On <span className='fw-bold'> {formatDate(item?.PublishedOn)} </span> by <span className='fw-bold'> {item?.AutorName} </span>
                                    </p>
                                </div>
                                <div className="mn-post-small-title">
                                    <h2>{item?.Title}</h2>
                                    <p>{item?.BodyOverview} <span>Continue Reading</span></p>
                                </div>
                            </Link>
                        </div>
                    )}
                })}
            </div>
        </Row>
    )
}

export default MarketNews
