import { Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import { Link } from "react-router-dom";
import "./index.css";

const SiteMap = () => {
  const topics = useSelector((state) => state.CommonReducer.allSlugs);

  return (
    <Container className="topic-details">
      <div className="breadcrumb">
        <span className="pe-1 unactive">
          <Link to={"/"}>Home</Link>
        </span>
        <span className="pe-1 greater">
          <img src={ArrowLeft} width={14} alt="greater than" />
        </span>
        <span className="activelink">
          <Link to={"/blogs"}>Sitemap</Link>
        </span>
      </div>
      <Row>
        <div className="col-md-12">
          <ul className="sitemap-list">
            {topics
              ?.filter((x) => x.Type === "Topic")
              .map((item, index) => (
                <li key={`sitemap-list-${index}`}>
                  <Link to={`/${item.Slug}`} className="text-decoration-none">
                    <p> {item.Slug} </p>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </Row>
    </Container>
  );
};

export default SiteMap;
