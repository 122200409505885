import React from 'react'
import SectionHeadingDescription from '../../Common/SectionHeadingDescription'
import './index.css'

const ContentBlockWithImage = ({ headingText, descriptionOne, descriptionTwo, sectionImg }) => {
    return (
        <div className="container">
            <div className="row align-items-center justify-content-between">
                <div className="col-md-6">
                    <SectionHeadingDescription headingText={headingText} descriptionOne={descriptionOne} descriptionTwo={descriptionTwo} />
                </div>
                <div className="col-md-6 text-end">
                    <img src={sectionImg} alt={sectionImg} />
                </div>
            </div>
        </div>
    )
}

export default ContentBlockWithImage
