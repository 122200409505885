import './index.css';

const AboutPage = () => {
    return (
      <div className="main">
        <h1> abcd</h1>  
          <p>Global About</p>
      </div>
    );
}
  
export default AboutPage;
