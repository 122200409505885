import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import HeadingDescription from "../SectionHeadingDescription";
import CloseIcon from "../../../Assests/Images/Common/close-dark.svg";
import Input from "../input";
import { Link } from "react-router-dom";
import Button from "../CustomBtn";
import { Row } from "react-bootstrap";
import MobileTracking from "../../../Assests/Images/Common/mobile-tracking-screen.png";
import AppStore from "../../../Assests/Images/Common/appstore.png";
import PlayStore from "../../../Assests/Images/Common/googleplay.png";
import "./style.css";

const RequestAQuote = (props) => {
  const { onHide, productimage, productname } = props;
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [show, setShow] = useState(false);

  const handleOnClickSubmitRequest = async () => {
    setShow(true);
    onHide();
  };

  const handleClose = () => setShow(false);

  return (
    <div className="quote-modal">
      {/* request-modal */}
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0 main-form">
          <div className="d-flex justify-content-end modal-close">
            <img
              src={CloseIcon}
              alt="Close"
              width={16}
              className="cursor-pointer"
              onClick={onHide}
            />
          </div>
          <Row>
            <div className="col-lg-5 modal-image pe-0">
              <img
                src={productimage}
                alt={productimage}
                loading="lazy"
              />
            </div>
            <div className="col-lg-7 pt-4 pb-4">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-11 col-xl-10 col-xl-9 request-headings">
                  <HeadingDescription
                    headingText={
                      "Get Your Instant Quote, Share Your Details for a Personalized Callback"
                    }
                    descriptionOne={
                      "Experience Hassle-free Procurement. Let Us Reach Out and Provide You with Tailored Solutions"
                    }
                  />
                </div>
              </div>
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-11 col-xl-10 col-xl-9">
                  <div className="form-group">
                    <Input
                      className={"form-control text-center"}
                      placeholder={productname}
                      type="text"
                      name={"productname"}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-11 col-xl-10 col-xl-9">
                  <div className="form-group">
                    <Input
                      className={"form-control text-center"}
                      placeholder={"Enter Your Name"}
                      type="text"
                      name={"name"}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-11 col-xl-10 col-xl-9">
                  <div className="form-group">
                    <Input
                      className={"form-control text-center"}
                      placeholder={"+92 333 1234567"}
                      type="text"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      name={"Phone Number"}
                    />
                  </div>
                </div>
                <div className="col-lg-11 col-xl-10 col-xl-9">
                  <div className="form-group">
                    <Input
                      className={"form-control text-center"}
                      placeholder={"Email Address"}
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      name={"Email Address"}
                    />
                  </div>
                  <Button
                    className={"primary-btn w-100 text-center ripple"}
                    text="SUBMIT REQUEST"
                    onClick={handleOnClickSubmitRequest}
                  />
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* thankyou-modal */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="p-0 main-form">
          <div className="d-flex justify-content-end modal-close">
            <img
              src={CloseIcon}
              alt="Close"
              width={16}
              className="cursor-pointer"
              onClick={handleClose}
            />
          </div>
          <Row className="justify-content-center">
            <div className="col-md-10 pt-4 pb-4 d-flex flex-column">
              <div className="row align-items-center justify-content-center pb-3">
                <div className="col-md-11 request-headings">
                  <HeadingDescription
                    headingText={
                      "Requested Completed! Get Seamless Order Tracking with Zaraye Mobile App"
                    }
                    descriptionOne={
                      "Stay Informed Every Step of the Way. Download our app and effortlessly track your orders in real-time for a seamless shopping experience."
                    }
                  />
                </div>
              </div>
              <img src={MobileTracking} alt={MobileTracking} loading="lazy" />
              <div className="d-flex mt-3 resp-apps-icons">
                <Link
                  target="_blank"
                  to={
                    "https://play.google.com/store/apps/details?id=com.zaraye.app"
                  }
                >
                  <img
                    src={PlayStore}
                    alt={PlayStore}
                    loading="lazy"
                    width={182}
                    className="mr-3"
                  />
                </Link>
                <Link
                  target="_blank"
                  to={"https://apps.apple.com/app/id1608301344"}
                >
                  <img
                    src={AppStore}
                    alt={AppStore}
                    loading="lazy"
                    width={182}
                    className="ms-3"
                  />
                </Link>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RequestAQuote;
