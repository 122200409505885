import React from "react";
import Input from "../../Common/input";
import SelectDropdown from "../../Common/selectDropdown";
import Button from "../../Common/CustomBtn";

const QuoteForm = () => {
  const [selectedUnits, setSelectedUnits] = React.useState(null);
  const [selectCountry, setSelectedCountry] = React.useState(null);
  const [selectCity, setSelectedCity] = React.useState(null);

  const selectUnits = [
    { value: "005", label: "005" },
    { value: "5621", label: "5621" },
  ];
  const countrySelect = [
    { value: "Pakistan", label: "Pakistan" },
    { value: "Canada", label: "Canada" },
  ];
  const citySelect = [
    { value: "North karachi", label: "North karachi" },
    { value: "Saddar", label: "Saddar" },
  ];
  const handleSelectUnits = (selectedUnits) => {
    setSelectedUnits(selectedUnits);
  };
  const handleSelectCountry = (selectCountry) => {
    setSelectedCountry(selectCountry);
  };
  const handleSelectCity = (selectCity) => {
    setSelectedCity(selectCity);
  };

  return (
    <div className="global-quote-form">
      <h1 className="get-quote">Get a Custom Quote</h1>
      <p className="mb-0">
        Complete the Form Below to Receive a Customized Quote for Your Specific Sourcing Needs. 
      </p>
      <div className="row">
        <div className="col-md-6 col-lg-3">
          <Input
            className={"form-control"}
            placeholder={"Enter Your Name"}
            type="text"
            name={"name"}
          />
        </div>
        <div className="col-md-6 col-lg-3">
          <Input
            className={"form-control"}
            placeholder={"Phone Number"}
            type="tel"
            name={"Phone Number"}
          />
        </div>
        <div className="col-md-6 col-lg-3">
          <Input
            className={"form-control"}
            placeholder={"Email Address"}
            type="email"
            name={"Email Address"}
          />
        </div>
        <div className="col-md-6 col-lg-3">
          <Input
            className={"form-control"}
            placeholder={"Enter Product Name"}
            type="text"
            name={"productName"}
          />
        </div>
        <div className="col-md-6 col-lg-3 col-xl-2">
          <Input
            className={"form-control"}
            placeholder={"Quantity"}
            type="text"
            name={"quantity"}
          />
        </div>
        <div className="col-md-6 col-lg-3 col-xl-2">
          <SelectDropdown
            options={selectUnits}
            value={selectedUnits}
            onChange={handleSelectUnits}
          />
        </div>
        <div className="col-md-6 col-lg-3 col-xl-2">
          <SelectDropdown
            options={countrySelect}
            value={selectCountry}
            onChange={handleSelectCountry}
          />
        </div>
        <div className="col-md-6 col-lg-3 col-xl-2">
          <SelectDropdown
            options={citySelect}
            value={selectCity}
            onChange={handleSelectCity}
          />
        </div>
        <div className="col-md-6 col-lg-5 col-xl-4 mx-auto">
          <Button className={"secondary-btn w-100 text-center"}
            text="Get Your Quote Now"
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteForm;
