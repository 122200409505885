import { useContext } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'
import { Container, Row } from "react-bootstrap";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png"
import MobileApp from "../../../Assests/Images/Common/zaraye-app.png";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import CustomButton from "../../../Components/Common/CustomBtn";
import AppStore from "../../../Assests/Images/Common/appstore.png";
import PlayStore from "../../../Assests/Images/Common/googleplay.png";
import zarayeLogo from "../../../Assests/Images/Common/footer-logo.svg";
import zortalLogo from "../../../Assests/Images/Common/zortal-logo.svg";
import zortalApp from "../../../Assests/Images/Common/zortal-app.svg";
import LocalizationContext from "../../../Utils/LocalizationContext";
import './index.css'

const SolutionPage = ({ item }) => {
  const { t } = useContext(LocalizationContext)
  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta data-react-helmet="true" name="description" content={item?.MetaDescription ? item?.MetaDescription : ''} />
        <meta data-react-helmet="true" name="keywords" content={item?.MetaKeywords ? item?.MetaKeywords : ''} />
        <title>{item?.MetaTitle ? item?.MetaTitle : 'Zaraye - B2B Raw Material Platform'}</title>
        {
          item?.Slug &&
          <link rel="canonical" href={`http://app-react.zaraye.co/${item?.Slug}`} />
        }
      </Helmet>
      <section className="industry-landing-page mb-5">
        <Container className="mp-hero-container">
          <div className="breadcrumb">
            <span className="pe-1 unactive">
              <Link to={"/"}>
                Home
              </Link>
            </span>
            <span className="pe-1 greater">
              <img src={ArrowLeft} width={14} alt="greater than" />
            </span>
            <span className="activelink">
              Solution
            </span>
          </div>
          <Row className="sub-header-container">
            <div className="col-sm-11 col-lg-8 col-xl-7 mx-auto landing-header-title">
              <h1>{t('zaraye.marketplace.zarayesolutions.main.title')}</h1>
            </div>
            <div className="col-sm-12 col-lg-10 col-xl-8 mx-auto">
              <p className="mt-3 text-center">{t('zaraye.marketplace.zarayesolutions.main.description')}</p>
            </div>
          </Row>
          <Row className="align-items-center justify-content-between solution-resp">
            <div className="col-lg-6 solution-image">
              <img src={MobileApp} alt="Zaraye" className="img-fluid" loading="lazy" />
            </div>
            <div className="col-lg-6 solution-pg-title text-start text-sm-center text-lg-start">
              <div className="title-logo">
                <img src={zarayeLogo} alt={zarayeLogo} />
              </div>
              <HeadingDescription headingText={t('zaraye.marketplace.zarayeapp.text')} descriptionOne={t("zaraye.marketplace.zarayeapp.description")} />
              <div className="d-flex justify-content-between justify-content-sm-center justify-content-lg-start mt-5 solution-app">
                <Link className="sp-app-logo" to={'https://play.google.com/store/apps/details?id=com.zaraye.app'}>
                  <img
                    src={PlayStore}
                    alt={PlayStore}
                    loading="lazy"
                    width={182}
                  />
                </Link>
                <Link className="sp-app-logo" to={'https://apps.apple.com/app/id1608301344'}>
                  <img
                    src={AppStore}
                    alt={AppStore}
                    loading="lazy"
                    width={182}
                    className="ms-0 ms=md-3"
                  />
                </Link>
              </div>
            </div>
          </Row>
          <Row className="dw-zortal-section align-items-center">
            <div className="col-lg-6 solution-pg-title text-start text-sm-center text-lg-start order-2 order-lg-1">
              <div className="title-logo">
                <img src={zortalLogo} alt={zortalLogo} />
              </div>
              <HeadingDescription headingText={t('zaraye.marketplace.zortal.text')} descriptionOne={t("zaraye.marketplace.zortal.description")} />
              <div className="exp-zortal">
                <CustomButton text={'EXPLORE ZORTAL'} className={'primary-btn mt-3'} />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <img src={zortalApp} alt={zortalApp} className="img-fluid" />
            </div>
          </Row>

        </Container>
      </section>
    </>
  );
}

export default SolutionPage;
