import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LandingPagesMainTitle from '../../../Components/Zaraye/LandingPagesTitle'

const ThankYouPage = () => {
    return (
        <Container>
            <Row>
            <LandingPagesMainTitle headingText={'Zaraye Solutions - Empowering Sourcing Excellence'} descriptionOne={'Experience Seamless Sourcing with Our Cutting-Edge Application and ERP System, Tailored to Your Business Needs'} />
            </Row>
            <div className="breadcrumb">
                <span className="pe-1 unactive">
                    <Link to={"/"}>
                        Home
                    </Link>
                </span>
                <span className="pe-1 greater">
                    {/* <img src={ArrowLeft} width={14} alt="greater than" /> */}
                </span>
                <span className="pe-1 unactive">
                    <Link to>
                        Brands
                    </Link>
                </span>
                <span className="pe-1 greater">
                    {/* <img src={ArrowLeft} width={14} alt="greater than" /> */}
                </span>
                <span className="activelink">
                    {/* {brandData?.Name} */}
                </span>
            </div>
        </Container>
    )
}

export default ThankYouPage
