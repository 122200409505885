import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import iron from "../../../Assests/Images/Common/ironSteel.png";
import "./style.css";
import CustomButton from "../../../Components/Common/CustomBtn";

const SearchDetailPage = () => {

  return (
    <div className="search-pg">
      <Container className="thankyou-page">
        <div className="breadcrumb">
          <span className="pe-1 unactive">
            <Link to={"/"}>Home</Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" />
          </span>
          <span className="activelink">Search Detail</span>
        </div>
      </Container>
      <Container>
        <Row>
          <div className="col-lg-7 col-xl-8">
            <h6 className="fw-bold text-dark search-counts"><span>05</span> items found for <span>"fabric"</span></h6>
            <div className="search-product-list">
              <Link className="d-flex justify-content-between align-items-center text-decoration-none">
                <div className="spl-img">
                  <img src={iron} alt={iron} />
                </div>
                <div className="spl-content">
                  <div className="spl-content-category">
                    <p className="mb-1 spl-cat-name">Category:<span className="fw-bold"> Fabric</span></p>
                    <h4>Quilt & Duvet Covers</h4>
                    <p className="mb-0 spl-content-detail">Elevate your bedroom with our luxurious Quilt & Duvet Covers. Embrace comfort and style in one beautiful package</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="search-product-list">
              <Link className="d-flex justify-content-between align-items-center text-decoration-none">
                <div className="spl-img">
                  <img src={iron} alt={iron} />
                </div>
                <div className="spl-content">
                  <div className="spl-content-category">
                    <p className="mb-1 spl-cat-name">Category:<span className="fw-bold"> Fabric</span></p>
                    <h4>Quilt & Duvet Covers</h4>
                    <p className="mb-0 spl-content-detail">Elevate your bedroom with our luxurious Quilt & Duvet Covers. Embrace comfort and style in one beautiful package</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="search-product-list">
              <Link className="d-flex justify-content-between align-items-center text-decoration-none">
                <div className="spl-img">
                  <img src={iron} alt={iron} />
                </div>
                <div className="spl-content">
                  <div className="spl-content-category">
                    <p className="mb-1 spl-cat-name">Category:<span className="fw-bold"> Fabric</span></p>
                    <h4>Quilt & Duvet Covers</h4>
                    <p className="mb-0 spl-content-detail">Elevate your bedroom with our luxurious Quilt & Duvet Covers. Embrace comfort and style in one beautiful package</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-5 col-xl-4">
            <div className="search-product-articles">
              <div className="spa-title">
                <h2>Related Articles</h2>
              </div>
              <div className="spa-list">
                <Link to className="text-decoration-none">
                  <p className="spa-published mb-2">Published On <span className="fw-bold">July 19, 2023</span> by <span className="fw-bold">Rida Shammas</span></p>
                  <h6>How To Choose The Best Bath Linen And Bath Towels For Hotels</h6>
                  <p>If you're a hotelier or in the hospitality industry, this blog post will</p>
                </Link>
              </div>
              <div className="spa-list">
                <Link to className="text-decoration-none">
                  <p className="spa-published mb-2">Published On <span className="fw-bold">July 19, 2023</span> by <span className="fw-bold">Rida Shammas</span></p>
                  <h6>How To Choose The Best Bath Linen And Bath Towels For Hotels</h6>
                  <p>If you're a hotelier or in the hospitality industry, this blog post will</p>
                </Link>
              </div>
            </div>
          </div>
          
        </Row>
        <div className="mp-home mt-3 mt-lg-5">
          <Row className="searh-product- popular-products-section mt-0">
            <div className="col-md-12 mb-4">
              <h2 >Related Categories</h2>
              <p>Explore our diverse textile categories to find the perfect fabrics for your projects. Quality materials for every need.</p>
            </div>
            <div className="col-md-12 d-flex flex-wrap grid-product position-relative">
              <div className="card products p-1">
                <Link to className="text-decoration-none inner-products">
                  <div className="position-relative prod-box-height cat-thumbnail">
                    <img src={iron} alt={iron} className="w-100 cat-img-fix" loading='lazy' />
                    <div className="overlay">
                      <div className="buttons">
                        <CustomButton text='ADD TO CART' className='secondary-btn' />
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="card-body d-flex flex-column justify-content-between">
                  <Link to={"/"} className="text-decoration-none">
                    <h5 className="mb-0 mt-1">Product Name</h5>
                  </Link>
                  <h6 className="mt-2" >
                    Get a Quote
                  </h6>
                </div>
              </div>
              <div className="card products p-1">
                <Link to className="text-decoration-none inner-products">
                  <div className="position-relative prod-box-height cat-thumbnail">
                    <img src={iron} alt={iron} className="w-100 cat-img-fix" loading='lazy' />
                    <div className="overlay">
                      <div className="buttons">
                        <CustomButton text='ADD TO CART' className='secondary-btn' />
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="card-body d-flex flex-column justify-content-between">
                  <Link to={"/"} className="text-decoration-none">
                    <h5 className="mb-0 mt-1">Product Name</h5>
                  </Link>
                  <h6 className="mt-2" >
                    Get a Quote
                  </h6>
                </div>
              </div>
              <div className="card products p-1">
                <Link to className="text-decoration-none inner-products">
                  <div className="position-relative prod-box-height cat-thumbnail">
                    <img src={iron} alt={iron} className="w-100 cat-img-fix" loading='lazy' />
                    <div className="overlay">
                      <div className="buttons">
                        <CustomButton text='ADD TO CART' className='secondary-btn' />
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="card-body d-flex flex-column justify-content-between">
                  <Link to={"/"} className="text-decoration-none">
                    <h5 className="mb-0 mt-1">Product Name</h5>
                  </Link>
                  <h6 className="mt-2" >
                    Get a Quote
                  </h6>
                </div>
              </div>
              <div className="card products p-1">
                <Link to className="text-decoration-none inner-products">
                  <div className="position-relative prod-box-height cat-thumbnail">
                    <img src={iron} alt={iron} className="w-100 cat-img-fix" loading='lazy' />
                    <div className="overlay">
                      <div className="buttons">
                        <CustomButton text='ADD TO CART' className='secondary-btn' />
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="card-body d-flex flex-column justify-content-between">
                  <Link to={"/"} className="text-decoration-none">
                    <h5 className="mb-0 mt-1">Product Name</h5>
                  </Link>
                  <h6 className="mt-2" >
                    Get a Quote
                  </h6>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default SearchDetailPage;
