import { useEffect, useState } from 'react';
import CommonService from '../../../Services/Common/CommonService';
import { Container, Row } from 'react-bootstrap';
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png"
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const BlogDetailPage = ({ item }) => {
  
  const [blogData, setBlogData] = useState({});

  const getBlogDetail = async (id) => {
    var response = await CommonService.GetBlogById(id);
    if (response?.success) {
      setBlogData(response?.data)
    }
  }

  useEffect(() => {
    getBlogDetail(item?.Id);
  }, [item?.Id])

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta data-react-helmet="true" name="description" content={item?.MetaDescription ? item?.MetaDescription : ''} />
        <meta data-react-helmet="true" name="keywords" content={item?.MetaKeywords ? item?.MetaKeywords : ''} />
        <title>{item?.MetaTitle ? item?.MetaTitle : 'Zaraye - B2B Raw Material Platform'}</title>
        {
          item?.Slug &&
          <link rel="canonical" href={`https://www.react-app.zaraye.co/${item?.Slug}`} />
        }
      </Helmet>
      <Container className='topic-detail-pg'>
        <div className="breadcrumb">
          <span className="pe-1 unactive">
            <Link to={"/"}>
                Home
            </Link>
          </span>
          <span className="pe-1 greater">
              <img src={ArrowLeft} width={14} alt="greater than" />
          </span>
          <span className="pe-1 unactive">
            <Link to={"/blogs"}>
              Blogs
            </Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" />
          </span>
          <span className="activelink pe-1">
            <Link to>
              {blogData?.title}
            </Link>
          </span>
        </div>
        <Row>
          <div className='col-md-12'>
            <h2 className='text-center mb-4'> {blogData?.title}</h2>
            <div className="d-block mt-5">
              <h5 className='blog-published-by'>{blogData?.authorName}</h5>
              <p className='blog-published-date'>{blogData?.publishedOn}</p>
            </div>
            <div className="d-block mb-5">
              <img src={blogData?.picture} className='w-100' />
            </div>
            <p dangerouslySetInnerHTML={{ __html: blogData?.body }}></p>
          </div>
        </Row>
      </Container>
      
    </>
  );
}

export default BlogDetailPage;
