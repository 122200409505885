import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MarketNews from "../../../Components/Marketplace/MarketNews";
import LandingPagesMainTitle from "../../../Components/Zaraye/LandingPagesTitle";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import * as commonAction from '../../../Actions/Common/CommonAction';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from "react-bootstrap";
import Pagination from "../../../Components/Common/Pagination";
import "./style.css"

const BlogPage = () => {
    const allBlogs = useSelector(state => state.CommonReducer.allBlogs);
    const [pageIndex, setPageIndex] = useState(allBlogs?.PageIndex);
    const [isRunning, setIsRunning] = useState(true);
    const dispatch = useDispatch();

    const handlePageChange = (value) => {
        setIsRunning(false);
        setPageIndex(value);
    };

    useEffect(() => {
        if (!isRunning) {
            loadBlogs()
        }
      }, [pageIndex]);

    const loadBlogs = () => {
        dispatch(commonAction.fetchAllBlogs(pageIndex, 5));
    }

    return (
        <div className='container blogs-page'>
            <div className="breadcrumb">
                <span className="pe-1 unactive">
                    <Link to={"/"}>
                        Home
                    </Link>
                </span>
                <span className="pe-1 greater">
                    <img src={ArrowLeft} width={14} alt="greater than" />
                </span>
                <span className="activelink">
                    <Link to={"/blogs"}>
                        Blogs
                    </Link>
                </span>
            </div>
            <div className="about-bg">
                <LandingPagesMainTitle
                    headingText="Zaraye Blogs - Unveiling Insights and Expertise"
                    descriptionOne="Immerse Yourself in Zaraye's Engaging Blogs for Valuable Industry Insights and Thought-Provoking Expert Analysis"
                />
            </div>
            <MarketNews />

            <div className="row">
                {allBlogs?.Blogs?.map((item, index) => (
                    <div key={`blog-item-${index}`} className={`${index < 2 ? "col-md-6" : "col-md-4"} mb-4 mt-4`} >
                        <Link to={`/${item?.SeName}`} className='text-decoration-none'>
                            <Row className='blogs-main p-0'>
                                <div className="mn-thubmnail">
                                    <img src={item?.Picture} alt={item?.Picture} className='w-100 img-fluid' />
                                </div>
                                <div className="mn-publish-detail">
                                    <p className='mt-4 mb-2'>
                                        Published On <span className='fw-bold'> {item?.PublishedOn} </span> by <span className='fw-bold'> {item?.AutorName} </span>
                                    </p>
                                </div>
                                <div className="mn-post-title">
                                    <h2 className={index < 2 ? "col-6-title" : "col-4-title"}>{item?.Title}</h2>
                                    <p>{item?.BodyOverview}</p>
                                </div>
                            </Row>
                        </Link>
                    </div>
                ))}
            </div>
            {allBlogs?.TotalPages > 1 && (
                <div className="pagination-box pe-4">
                    <Pagination
                        HasNextPage={allBlogs?.HasNextPage}
                        HasPreviousPage={allBlogs?.HasPreviousPage}
                        currentPage={pageIndex}
                        totalPages={allBlogs?.TotalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            )}
        </div>
    );
}

export default BlogPage;
