import React, { useContext } from 'react'
import { Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react';
import GetQuoteForm from "../../../Components/Common/GetQuoteForm";
import banner from '../../../Assests/Images/MarketPlace/landing-banner.png'
import RealTimePrice from "../../../Components/Common/RealTimePrice";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import OurProducts from "../../../Components/Common/Products";
import { useSelector } from 'react-redux';
import CommonService from '../../../Services/Common/CommonService';
import { Helmet } from "react-helmet";
import OurPopularProducts from "../../../Components/Common/OurPopularProducts";
import ManufacturesAndBrands from "../../../Components/Common/ManufacturesAndBrands";
import OurCustomersSlider from "../../../Components/Common/OurCustomersSlider";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png"
import LocalizationContext from "../../../Utils/LocalizationContext";

import './index.css'

const IndustryDetailPage = ({ item }) => {
  const { t } = useContext(LocalizationContext);

  const loadAllRates = useSelector((state) => state.CommonReducer.allRates);
  const Categories = useSelector((state) => state.CommonReducer.industriesAndCategories);
  const [industryData, setIndustryData] = useState({});
  const getIndustryDetail = async (id) => {
    var response = await CommonService.GetIndustriesDetail(id);
    if (response?.success) {
      setIndustryData(response?.data)

    }
  }
  useEffect(() => {
    getIndustryDetail(item?.Id);
  }, [item?.Id]);



  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta data-react-helmet="true" name="description" content={item?.MetaDescription ? item?.MetaDescription : ''} />
        <meta data-react-helmet="true" name="keywords" content={item?.MetaKeywords ? item?.MetaKeywords : ''} />
        <title>{item?.MetaTitle ? item?.MetaTitle : 'Zaraye - B2B Raw Material Platform'}</title>
        {
          item?.Slug &&
          <link rel="canonical" href={`https://www.react-app.zaraye.co/${item?.Slug}`} />
        }
      </Helmet>
      <section className="industry-landing-page">
        <Container className="mp-hero-container">
          <div className="breadcrumb">
            <span className="pe-1 unactive">
              <Link to={"/"}>
                Home
              </Link>
            </span>
            <span className="pe-1 greater">
              <img src={ArrowLeft} width={14} alt="greater than" />
            </span>
            <span className="pe-1 unactive">
              <Link to={"/"}>
                Industry
              </Link>
            </span>
            <span className="pe-1 greater">
              <img src={ArrowLeft} width={14} alt="greater than" />
            </span>
            <span className="activelink">
              {/* <Link to> */}
              {item?.Title}
              {/* </Link> */}
            </span>
          </div>
          <Row>
            <div className="col-lg-8 col-xl-9">
              <div className="hero-industies-slider-row position-relative">
                <div className="his-img h-100">
                  <img src={industryData?.Picture} alt={industryData?.Name} loading="lazy" />
                </div>
                <Row className="his-content">
                  <div className="col-lg-9 col-xl-7">
                    <Link to={industryData?.SeName} >
                      <h1 className='text-white'>{industryData?.Name}</h1>
                      <p className='text-white' dangerouslySetInnerHTML={{ __html: industryData?.Description }}></p>
                      EXPLORE Products
                    </Link>
                  </div>
                </Row>
              </div>
            </div>
            <div className="col-lg-4 col-xl-3">
              <GetQuoteForm formTitle={t('zaraye.marketplace.customquote.text')} formDescription={t("zaraye.marketplace.customquote.description")} />
            </div>
          </Row>
        </Container>
        {loadAllRates.filter(x => x?.IndustryId === item?.Id).length > 0 &&
          <Container className="textile-price-table">
            <Row>
              {/* <div className="col-md-12"> */}
              <HeadingDescription headingText={`${industryData?.Name} Price in Pakistan - Real-Time ${industryData?.Name} Live Daily Rates`} descriptionOne={`Stay informed with real-time ${industryData?.Name} price updates in Pakistan. Explore live daily rates to make informed decisions in the ${industryData?.Name} industry.`} />
              {
                loadAllRates.filter(x => x?.IndustryId === item?.Id).length > 0 &&
                <RealTimePrice data={loadAllRates.filter(x => x?.IndustryId === item?.Id)} />
              }
              {/* </div> */}
            </Row>
          </Container>
        }
        <Container className="explore-categories-section position-relative">
          <Row>
            <OurProducts data={industryData?.Categories} headingText= {t('zaraye.marketplace.exploreindustrycategories.text', { industryName: industryData?.Name })}
            descriptionOne={t('zaraye.marketplace.exploreindustrycategories.description', {industryName: industryData?.Name})} />
          </Row>
        </Container>
        {industryData?.BestSellingProducts?.length &&
          <Container className="discover-best-selling-section">
            <Row>
              <OurProducts data={industryData?.BestSellingProducts} headingText={t('zaraye.marketplace.topsellingindustryproducts.text', {industryName: industryData?.Name})} descriptionOne={t('zaraye.marketplace.topsellingindustryproducts.text', {industryName: industryData?.Name})} />
            </Row>
          </Container>
        }
        <section className='position-relative mt-5'>
          <ManufacturesAndBrands data={industryData?.Brands} />
        </section>
        <Container className="featured-product-section">
          <Row>
            <OurProducts data={industryData?.Products} headingText={t('zaraye.marketplace.featuredindustryproduct.text', {industryName: industryData?.Name})} descriptionOne={t('zaraye.marketplace.featuredindustryproduct.description', {industryName: industryData?.Name})} />
          </Row>

        </Container>
        <OurCustomersSlider />
        <GetZarayeApp />
      </section>
    </>
  );
}

export default IndustryDetailPage;
