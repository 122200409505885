import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import CustomButton from "../CustomBtn";
import FilterDropdown from "../Filter";
import { Container, Row } from "react-bootstrap";
import RequestAQuote from '../RequestModal';
import './index.css'

const ProductsGridItem = ({ data = {} }) => {
    const [modalShow, setModalShow] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});

    const pageSize = [
        {
            Name: '5'
        },
        {
            Name: '10'
        },
        {
            Name: '15'
        },
        {
            Name: '20'
        }
    ]

    // const [isGridView, setIsGridView] = useState(true);

    // const toggleGridView = () => {
    //     setIsGridView(true);
    // };
    // const toggleListView = () => {
    //     setIsGridView(false);
    // };

    return (
        <Container>
            <Row className='grid-filter-row'>
                <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className="grid-layout d-flex align-items-center">
                        {/* <div className="toggle-buttons">
                            <button onClick={toggleGridView} className={`bg-transparent border-0 ${isGridView ? 'active' : ''}`}>
                                <img src={gridIcon} alt={gridIcon} />
                            </button>
                            <button onClick={toggleListView} className={`bg-transparent border-0 ${isGridView ? 'active' : ''}`}>
                                <img src={listIcon} alt={listIcon} />
                            </button>
                        </div> */}
                        <div className="filter-page-size">
                            <p className="text-dark mb-0">
                                Showing <span className="fw-bold"> {data?.Products?.length} </span> Items of Total <span className="fw-bold"> {data?.TotalCount} </span> Items
                            </p>
                            {/* <FilterDropdown title={'Showing'} pageSize={pageSize} /> */}
                        </div>
                    </div>
                    <div className="sorting">
                        <FilterDropdown title={'Featured'} pageSize={pageSize} />
                    </div>
                </div>
            </Row>
            <Row>
                <div className="col-md-12 d-flex flex-wrap grid-product">
                    {/* <div className={`d-flex flex-wrap grid-product`}> */}
                    {data?.Products?.map((item, index) => (
                        <div key={`products-${index}`} className="card products">
                            <Link to={`/${item.SeName}`} className="text-decoration-none inner-products">
                                <div className="position-relative prod-box-height cat-thumbnail">
                                    <img src={item?.Picture} alt={item?.Name} className="w-100 cat-img-fix" loading='lazy' />
                                    <div className="overlay">
                                        <div className="buttons">
                                            <CustomButton text='ADD TO CART' className='secondary-btn' />
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div className="card-body d-flex flex-column justify-content-between">
                                <Link to={`/${item.SeName}`} className="text-decoration-none">
                                    <h5 className="mb-0 mt-1">{item?.Name}</h5>
                                </Link>
                                <h6 
                                    className="mt-2" 
                                    onClick={() => {
                                        setSelectedProduct(item);
                                        setModalShow(true);
                                    }}
                                >
                                    Get a Quote
                                </h6>
                            </div>
                        </div>
                    ))}
                    <RequestAQuote
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        productimage={selectedProduct?.Picture}
                        productname={selectedProduct?.Name}
                    />
                </div>
            </Row>
        </Container >
    )
}

export default ProductsGridItem
