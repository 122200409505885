import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomBtn from '../../Common/CustomBtn';
import SelectDropdown from "../../Common/selectDropdown";
import './style.css';

const customStyles = {
  // Style for the container surrounding the Select component
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  container: (provided, state) => ({
    ...provided,
    border: '1px solid teal',
    borderRadius: '40px',
    padding: '7px 10px',
  }),

  // Style for the control (input)
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    border: 'none',
  }),

  // Style for the dropdown indicator arrow
  // dropdownIndicator: (provided, state) => ({
  //   ...provided,
  //   color: 'gray',
  // }),

  // Style for the options in the dropdown menu
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'teal' : 'white',
    color: state.isSelected ? 'white' : 'black',
    '&:hover': {
      backgroundColor: 'teal',
      color: 'white',
    },
  }),
};

function CustomModal({ show, handleClose }) {
  const [selectedUnits, setSelectedUnits] = React.useState(null);

  const selectUnits = [
    { value: "005", label: "005" },
    { value: "5621", label: "5621" },
  ];
  const handleSelectUnits = (selectedUnits) => {
    setSelectedUnits(selectedUnits);
  };
  return (

    <Modal centered show={show} onHide={handleClose}>
      <div className="myModal modal-form">
        {/* <Modal.Header closeButton> */}
        {/* </Modal.Header> */}
        <Modal.Body>
          <Modal.Title><h1 className='modalTitle'>We think you are in UAE, Update your location?</h1></Modal.Title>
          <p className='text-center'>Let's Get It Right! Help Us Serve You Better by Confirming Your Country</p>
          <div className="form-group">
            <SelectDropdown
              options={selectUnits}
              value={selectedUnits}
              onChange={handleSelectUnits}
              styles={customStyles}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CustomBtn text='CONTINUE WITH USA' className='secondary-btn' />
          <CustomBtn text='CHANGE LOCATION' className='primary-btn' onClick={handleClose} />
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default CustomModal;
