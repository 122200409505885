import React, { useContext } from 'react'
import FooterLogo from "../../../Assests/Images/Zaraye/footer-logo.svg";
import AppStore from "../../../Assests/Images/Common/appstore.png";
import PlayStore from "../../../Assests/Images/Common/googleplay.png";
import Facebook from "../../../Assests/Images/Zaraye/facebook-round.svg";
import Linkedin from "../../../Assests/Images/Zaraye/linkedin-round.svg";
import Twitter from "../../../Assests/Images/Zaraye/twitter-round.svg";
import { Link } from "react-router-dom";
import Newsletter from "../../Common/Newsletter";
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./style.css";
import { useSelector } from 'react-redux';

const Footer = () => {
  const industriesAndCategories = useSelector((state) => state.CommonReducer.industriesAndCategories);
  const { t } = useContext(LocalizationContext);

  const FooterLinks = [
    {
      heading: t('zaraye.marketplace.discovermore.text'),
      links: [
        { text: t("zaraye.marketplace.products.text"), route: "/product" },
        { text: t("zaraye.marketplace.priceinsights.text"), route: "/realtimepricing" },
        { text: t("zaraye.marketplace.brands.text"), route: "/brand" },
        { text: t("zaraye.marketplace.blogs.text"), route: "/blogs" },
        { text: t("zaraye.marketplace.becomeasupplier.text"), route: "/become-a-supplier" },
      ],
    },
    {
      heading: t('zaraye.marketplace.support.text'),
      links: [
        { text: t("contactus"), route: "/contactus" },
        { text: t("zaraye.marketplace.faqs.text"), route: "/faqs" },
        { text: t("zaraye.marketplace.privacypolicy.text"), route: "/privacy-policy" },
        { text: t("zaraye.marketplace.termsconditions.text"), route: "/terms" },
      ],
    },
    {
      heading: t('zaraye.marketplace.explore.text'),
      links: [
        { text: t("zaraye.marketplace.solutions.text"), route: "/solution" },
        { text: t("zaraye.marketplace.zarayeglobal.text"), route: "/global" },
        { text: t("zaraye.marketplace.company.text"), route: "/company" },
        { text: t("zaraye.marketplace.sitemap.text"), route: "/sitemap" },
        { text: t("zaraye.marketplace.careers.text"), route: "/careers" },
      ],
    },
  ];
  return (
    <>
      <div className="footer-container">
        <Newsletter />
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-xl-4 col-lg-12 left-side">
              <img src={FooterLogo} alt={FooterLogo} loading="lazy" className="zaraye-footer-logo" />
              <p className="py-4">{t("zaraye.marketplace.footer.description")}</p>
              <div className="d-flex justify-content-center justify-content-xl-start">
                <Link target="_blank" to={'https://www.facebook.com/zaraye.co?_rdc=1&_rdr'}>
                  <img
                    src={Facebook}
                    alt={Facebook}
                    loading="lazy"
                    className="mx-2"
                  />
                </Link>
                <Link target="_blank" to={'https://www.linkedin.com/company/zaraye/mycompany/verification/'}>
                  <img
                    src={Linkedin}
                    alt={Linkedin}
                    loading="lazy"
                    className="mx-2"
                  />
                </Link>
                <Link target="_blank" to={'https://twitter.com/i/flow/login?redirect_after_login=%2Fzarayetech'}>
                  <img
                    src={Twitter}
                    alt={Twitter}
                    loading="lazy"
                    className="mx-2"
                  />
                </Link>
              </div>
            </div>
            <div className="col-xl-7 col-lg-12 right-side">
              <div className="row d-flex">
                <div className="col-6 col-sm-3 footer-links">
                  <h5>{t("zaraye.marketplace.quicklinks.text")}</h5>
                  <ul className="list-group list-unstyled footer-links-items">
                    {industriesAndCategories?.map((item, index) => (
                      <li key={`industries-${index}`}>
                        <Link className="text-decoration-none" to={`/${item?.SeName}`}>{item?.Name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
                {FooterLinks.map((item, index) => (
                  <div key={`footer-links-${index}`} className="col-6 col-sm-3 footer-links">
                    <h5>{item.heading}</h5>
                    <ul className="list-group list-unstyled footer-links-items">
                      {item.links?.map((item, index) => (
                        <li key={`footer-options-${index}`}>
                          <Link className="text-decoration-none" to={item?.route}>{item?.text}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between footer-copyright">
            <p className="mb-0 pe-3">
              {t("zaraye.marketplace.copyright.text")}
            </p>
            <div className="d-flex footer-bottom-images">
              <Link target="_blank" to={'https://play.google.com/store/apps/details?id=com.zaraye.app'}>
                <img
                  src={PlayStore}
                  alt={PlayStore}
                  loading="lazy"
                  className="mr-3"
                />
              </Link>
              <Link target="_blank" to={'https://apps.apple.com/app/id1608301344'}>
                <img
                  src={AppStore}
                  alt={AppStore}
                  loading="lazy"
                  className="ms-3"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
