import RestClient from '../RestClient';

const SupplierRegister = (payload) => {
    return RestClient.Post(`security/register-supplier/`, payload);
}

const exportedObject = {
    SupplierRegister,
};

export default exportedObject;