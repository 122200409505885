import React from 'react'
import { Container, Row } from 'react-bootstrap'
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import { Link } from 'react-router-dom'
import HeroMainTitle from '../../../Components/Common/HeroMainTitle';
import GetQuoteForm from '../../../Components/Common/GetQuoteForm';
import bgLogo from "../../../Assests/Images/Common/bg-logo.png";
import GetZarayeApp from '../../../Components/Common/getZarayeApp';
import './index.css'

const GetAFinishedGoodsQuote = () => {
    return (
        <div className='seo-landing-pg'>
            <div className="landing-bg-logo position-absolute">
                <img src={bgLogo} />
            </div>
            <Container>
                <div className="breadcrumb">
                    <span className="pe-1 unactive">
                        <Link to={"/"}>Home</Link>
                    </span>
                    <span className="pe-1 greater">
                        <img src={ArrowLeft} width={14} alt="greater than" />
                    </span>
                    <span className="activelink">Get a Finish Goods quote</span>
                </div>
                <Row className='justify-content-between align-items-center'>
                    <div className="col-md-12 col-lg-6 mb-5 mb-lg-0">
                        <HeroMainTitle topTagLine={'GET A FINISH GOODS QUOTE'} title={'Get Fast & Efficient Delivery'} description={'Of the best Textile Apparels and Garments at the Best Rates Globally!'} btnText={'Explore MARKETPLACE'} linkText={'Get a Customized Quote for Your Product'} />
                    </div>
                    <div className="col-lg-6 col-xl-5">
                        <GetQuoteForm source={'Finished Goods'} formTitle={'Get a Finish Goods Quote'} formDescription={'Fill out the form below and our team will provide you with a tailored quote for your finished goods material requirements.'} />
                    </div>
                </Row>
            </Container>
            <GetZarayeApp />
        </div>
    )
}

export default GetAFinishedGoodsQuote
