import * as ActionTypes from '../../Actions/ActionTypes';

const initialState = {
    shoppingCartItems: [],
}

const ShoppingCartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_SHOPPING_CART_ITEMS:
            return {
                ...state,
                shoppingCartItems: action.data
            };
        default:
            return state;
    }

};

export default ShoppingCartReducer;