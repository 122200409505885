import React, { useContext } from 'react'
import MobileApp from "../../Assests/Images/Common/zaraye-app.svg";
import GooglePlay from "../../Assests/Images/Common/googleplay.png";
import AppStore from "../../Assests/Images/Common/appstore.png";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import LocalizationContext from "../../Utils/LocalizationContext";


const GetZarayeApp = () => {
  const { t } = useContext(LocalizationContext);

  return (
    <div className="explore-product-section get-zaraye-app-section">
      <div className="container">
        <Row className="align-items-center zaraye-app">
          <div className="col-xl-6 col-lg-5 col-12 img-column">
            <div className="d-flex align-items-center justify-content-center app-logo">
              <img src={MobileApp} alt="Zaraye" loading="lazy" />
            </div>
          </div>
          <div className="col-xl-6 col-lg-7 explore-prod-left-column">
            <h1 className="main-heading">
              {t("zaraye.marketplace.getthezarayeappseamless.text")}
            </h1>
            <p>{t("zaraye.marketplace.getthezarayeappseamless.description")} </p>
            <Row className="pt-2 d-none d-lg-flex">
              <div className="col-xxl-4 col-lg-5 col-12 me-3">
                <Link to='https://play.google.com/store/apps/details?id=com.zaraye.app' target="_blank">
                  <img src={GooglePlay} alt={GooglePlay} height={60} />
                </Link>
              </div>
              <div className="col-xxl-4 col-lg-5">
                <Link to='https://apps.apple.com/app/id1608301344' target="_blank">
                  <img src={AppStore} alt={AppStore} height={60} />
                </Link>
              </div>
            </Row>
          </div>
          <Row className="d-lg-none d-flex explore-prod-img-column">
            <div className="col-5 me-3 app-images">
              <Link to='https://play.google.com/store/apps/details?id=com.zaraye.app' target="_blank">
                <img src={GooglePlay} alt={GooglePlay} />
              </Link>
            </div>
            <div className="col-5 app-images">
              <Link to='https://apps.apple.com/app/id1608301344' target="_blank">
                <img src={AppStore} alt={AppStore} />
              </Link>
            </div>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default GetZarayeApp;
