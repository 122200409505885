import React, { useContext, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import IncreaseQty from "../../../Assests/Images/Common/increase.svg";
import DecreaseQty from "../../../Assests/Images/Common/decrease.svg";
import ShoppingCart from "../../../Assests/Images/MarketPlace/shopping-cart.png";
import ShopingCartService from "../../../Services/ShopingCart/ShopingCartService";
import * as shoppingCartAction from "../../../Actions/ShoppingCart/ShoppingCartAction";
import CustomButton from "../../../Components/Common/CustomBtn";
import RequestService from "../../../Services/Request/RequestService";
import Bin from "../../../Assests/Images/Common/bin.svg";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../Components/Common/input";
import { toast } from "react-toastify";
import "./style.css";
import LocalizationContext from "../../../Utils/LocalizationContext";

const CartDetailPage = () => {
  const {t} = useContext(LocalizationContext)
  const shoppingCartItems = useSelector(
    (state) => state.ShoppingCartReducer.shoppingCartItems
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState(1);

  const handleIncrement = (itemId, currentQuantity) => {
    const newQuantity = currentQuantity + 1;
    handleOnClickUpdateItem(itemId, newQuantity);
  };

  const handleDecrement = (itemId, currentQuantity) => {
    if (currentQuantity > 1) {
      const newQuantity = currentQuantity - 1;
      handleOnClickUpdateItem(itemId, newQuantity);
    }
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue) && newValue >= 1 && newValue <= 99999) {
      setValue(newValue);
    }
  };

  const handleOnClickRemoveItem = async (id) => {
    var result = await ShopingCartService.DeleteCartItem(id);
    if (result?.success) {
      dispatch(shoppingCartAction.fetchShoppingCartItems());
    }
  };

  const handleOnClickUpdateItem = async (id, quantity) => {
    var result = await ShopingCartService.UpdateCartItemQuatity(id, quantity);
    if (result?.success) {
      dispatch(shoppingCartAction.fetchShoppingCartItems());
    }
  };

  const handleOnClickSubmitRequest = async () => {
    if (name == "") {
      toast.error(t('zaraye.marketplace.pleaseenteryourname.error'));
    } else if (phoneNumber == "") {
      toast.error(t("zaraye.marketplace.pleaseenteryourphonenumber.error"));
    } else if (email == "") {
      toast.error(t("zaraye.marketplace.pleaseenteryouremail.error"));
    } else {
      var payload = {
        email: email,
        fullName: name,
        phone: phoneNumber,
      };
      var result = await RequestService.AddRequest(payload);
      if (result?.success) {
        dispatch(shoppingCartAction.fetchShoppingCartItems());
        navigate("/request-complete")
      } else {
        toast.error(result?.message);
      }
    }
  };

  return (
    <Container className="cart-page">
      <div className="breadcrumb">
        <span className="pe-1 unactive">
          <Link to={"/"}>Home</Link>
        </span>
        <span className="pe-1 greater">
          <img src={ArrowLeft} width={14} alt="greater than" />
        </span>
        <span className="activelink">Cart</span>
      </div>
      {shoppingCartItems.length > 0 ? (
        <>
          <HeadingDescription headingText={t('zaraye.marketplace.yourcart.text')} />
          <Row>
            <div className="col-md-12 col-lg-8">
              {shoppingCartItems?.map((item, index) => (
                <div
                  key={`shopping-cart-items-${index}`}
                  className={`cart-list ${item.removing ? "removing" : ""}`}
                >
                  <img
                    src={item?.Picture}
                    alt={item?.Name}
                    loading="lazy"
                    className="cart-img"
                  />
                  <div className="row w-100 ps-2 ps-sm-4 pe-3 cart-list-detail py-2 py-sm-3 position-relative">
                    <div className="col-md-7 cart-left d-flex flex-column justify-content-between">
                      <div className="d-flex flex-column">
                        <h5 className="section-heading">{item?.Name}</h5>
                        <div className="material">
                          {t('zaraye.marketplace.category.text')} <span>{item?.Name}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 d-flex">
                      <div className="cart-details d-flex justify-content-between w-100">
                        <div className="attribute-names">
                          {item?.ProductAttributes?.map((item2, index2) => {
                            return (
                              <span key={`item-product-attribute-${index2}`}>
                                {item2?.AttributeName}: <b>{item2?.Value}</b>
                              </span>
                            );
                          })}
                          <span>
                            {t('zaraye.marketplace.brand.text')} <b>{item?.BrandName}</b>
                          </span>
                        </div>
                        {/* <div className="d-flex align-items-center">
                          <img
                            src={Bin}
                            alt={Bin}
                            loading="lazy"
                            onClick={() =>
                              handleOnClickRemoveItem(item?.CartItemId)
                            }
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-1 d-flex align-items-center mt-auto">
                      <div className="d-flex align-items-center cursor-pointer cart-bin">
                        <img
                          src={Bin}
                          alt={Bin}
                          loading="lazy"
                          onClick={() =>
                            handleOnClickRemoveItem(item?.CartItemId)
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center form-item pe-0">
                        <span className="qty-meter">Qty(Meter):</span>
                        <div className="qtyField position-relative mx-2 mx-lg-3">
                          <input
                            type="number"
                            value={item?.Quantity}
                            min="1"
                            max="99999"
                            onChange={handleChange}
                          />
                          <div
                            className="decrease-qty"
                            onClick={() =>
                              handleDecrement(item?.CartItemId, item?.Quantity)
                            }
                          >
                            <img src={DecreaseQty} alt={DecreaseQty} width="30" />
                          </div>
                          <div
                            className="increase-qty"
                            onClick={() =>
                              handleIncrement(item?.CartItemId, item?.Quantity)
                            }
                          >
                            <img src={IncreaseQty} alt={IncreaseQty} width="30" />
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-12 col-lg-4 mt-4 mb-5 mb-lg-0 mt-lg-0">
              <div className="main-form">
                <h1 className="get-quote" >
                  <span>{t('zaraye.marketplace.readytoproceed.text')}</span> {t('zaraye.marketplace.requestyourquotenow.text')}
                </h1>
                <p className="mb-0">{t('zaraye.marketplace.readytoproceed.description')}</p>
                <div className="row mt-2">
                  <div className="form-group">
                    <Input
                      className={"form-control"}
                      placeholder={"Enter Your Name"}
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      name={"name"}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="form-group">
                    <Input
                      className={"form-control"}
                      placeholder={"Phone Number"}
                      type="tel"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      name={"Phone Number"}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="form-group">
                    <Input
                      className={"form-control"}
                      placeholder={"Email Address"}
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      name={"Email Address"}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12">
                    <CustomButton
                      className={"primary-btn w-100 text-center ripple"}
                      text={t("zaraye.marketplace.submitrequest.text")}
                      onClick={handleOnClickSubmitRequest}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </>
      ) : (
        <Row>
          <div className="card-is-empty">
            <img src={ShoppingCart} alt={ShoppingCart} loading="lazy" />
            <p>{t('zaraye.marketplace.shoppingcartisempty.text')}</p>
            <CustomButton
              className="primary-btn go-to-marketplace"
              text={t("zaraye.marketplace.backtomarketplace.text")}
              onClick={() => navigate("/")}
            />
          </div>
        </Row>
      )}
    </Container>
  );
};

export default CartDetailPage;
