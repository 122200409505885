import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../../Components/Marketplace/Header";
import Footer from "../../Components/Zaraye/Footer";
import AboutPage from "../../Pages/Zaraye/About";
import useScroll from "../../Components/Common/useScroll";

const ZarayeRoutes = () => {
  const isScrolled = useScroll();

  return (
    <div className="main-layout">
      <Header />
      <div className={`inner-layout ${isScrolled ? 'scrolled' : ''}`}>
        <Routes>
          <Route path="/about" element={<AboutPage/>} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};
export default ZarayeRoutes;
