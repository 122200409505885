import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'
import { Container, Row } from "react-bootstrap";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png"
import LandingPagesMainTitle from "../../../Components/Zaraye/LandingPagesTitle";
import CurrencySlider from "../../../Components/Common/CurrencySlider";
import Success from "../../../Assests/Images/Zaraye/mp-success.svg";
import Danger from "../../../Assests/Images/Zaraye/mp-danger.svg";
import SelectDropdown from "../../../Components/Common/selectDropdown";
import Input from "../../../Components/Common/input";
import Button from "../../../Components/Common/CustomBtn";
import RealTimePrice from "../../../Components/Common/RealTimePrice";
import Pagination from "../../../Components/Common/Pagination";
import { useSelector } from 'react-redux';
import './style.css'
import { useContext } from "react";
import LocalizationContext from "../../../Utils/LocalizationContext";


const RealTimePricePage = ({ item }) => {
  const loadAllRates = useSelector((state) => state.CommonReducer.allRates);
  const [selectCountry, setSelectedCountry] = useState(null);
  const { t } = useContext(LocalizationContext)

  const handleSelectCountry = (selectCountry) => {
    setSelectedCountry(selectCountry);
  };

  const quoteFormDropdownStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderRadius: '8px', // Add border-radius
      border: '1px solid teal',
      marginLeft: '-10px',
    }),

    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: '4px 8px',
    }),

    container: (provided, state) => ({
      ...provided,
      border: '1px solid teal',
      borderRadius: '40px',
      padding: '0 10px',
      background: '#ffffff42',
      marginTop: '10px',
      fontSize: '14px',
      fontFamily: 'Regular',
      color: '#000',
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      background: 'none',
      minHeight: '51px',
      border: '0',
      svg: {
        height: '17px',
        width: '17px',
      height:'50px',

      },
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#808080',
      border: '0',
      padding: '0',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'teal' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'teal',
        color: 'white',
        border: '2px solid',

      },
    }),
  };

  const countrySelect = [
    { value: "Pakistan", label: "Pakistan" },
    { value: "Canada", label: "Canada" },
  ];

  const items = [
    {
      icon: Danger,
      currency: "PKR333.34",
      country: "USD:",
      countryRate: "1.00%",
      value: false,
    },
    {
      icon: Success,
      currency: "PKR333.34",
      country: "INR:",
      countryRate: "2.30%",
      value: true,
    },
    {
      icon: Danger,
      currency: "PKR333.34",
      country: "AED:",
      countryRate: "0.0211%",
      value: false,
    },
    {
      icon: Success,
      currency: "PKR333.34",
      country: "BHD:",
      countryRate: "0.0211%",
      value: true,
    },
    {
      icon: Success,
      currency: "PKR333.34",
      country: "SAR:",
      countryRate: "0.0211%",
      value: true,
    },
    {
      icon: Danger,
      currency: "PKR333.34",
      country: "AUD:",
      countryRate: "0.0211%",
      value: false,
    },
    {
      icon: Success,
      currency: "PKR333.34",
      country: "BDT:",
      countryRate: "0.0211%",
      value: true,
    },
  ];

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta data-react-helmet="true" name="description" content={item?.MetaDescription ? item?.MetaDescription : ''} />
        <meta data-react-helmet="true" name="keywords" content={item?.MetaKeywords ? item?.MetaKeywords : ''} />
        <title>{item?.MetaTitle ? item?.MetaTitle : 'Zaraye -Real Time Price'}</title>
        {
          item?.Slug &&
          <link rel="canonical" href={`http://app-react.zaraye.co/${item?.Slug}`} />
        }
      </Helmet>
      <section className="industry-landing-page real-time-price-page">
        <Container className="mp-hero-container">
          <div className="breadcrumb">
            <span className="pe-1 unactive">
              <Link to={"/"}>
                Home
              </Link>
            </span>
            <span className="pe-1 greater">
              <img src={ArrowLeft} width={14} alt="greater than" />
            </span>
            <span className="activelink">
              {/* <Link to> */}
              Real Time Price
              {/* </Link> */}
            </span>
          </div>
          <LandingPagesMainTitle
            headingText={t("zaraye.marketplace.priceindex.main.title")}
            descriptionOne={t("zaraye.marketplace.priceindex.main.description")}
          />
          <Row className="price-insights-filters my-4">
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3 mb-lg-0">
              <Input
                className={"form-control"}
                placeholder={"Enter Your Name"}
                type="text"
                name={"name"}
              />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3 mb-lg-0">
              <SelectDropdown
                options={countrySelect}
                value={selectCountry}
                onChange={handleSelectCountry}
                styles={quoteFormDropdownStyles}
                placeholder={'Industry'}
              />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3 mb-lg-0">
              <SelectDropdown
                options={countrySelect}
                value={selectCountry}
                onChange={handleSelectCountry}
                styles={quoteFormDropdownStyles}
                placeholder={'Industry'}
              />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 brand-filter-btn mb-3 mb-lg-0">
              <Button
                className={"primary-btn w-100 text-center ripple"}
                text="APPLY FILTER"
              />
            </div>
          </Row>
          <Row className="mt-5">
            {
              loadAllRates?.length > 0 &&
              <RealTimePrice data={loadAllRates} />
            }
          </Row>
          <Row className="mt-5">
            <Pagination />
          </Row>
        </Container>
      </section>
    </>
  );
}

export default RealTimePricePage;
