import React, { useState } from 'react';
import Slider from "react-slick";
import HeadingDescription from '../SectionHeadingDescription';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as PreviousDark } from "../../../Assests/Images/Zaraye/previous.svg";
import { ReactComponent as NextDark } from "../../../Assests/Images/Zaraye/next.svg";
import CustomButton from "../../Common/CustomBtn";
import { useNavigate } from 'react-router-dom';
import './style.css'

const OurCategories = ({ headingText, descriptionOne, linkText }) => {
    const industriesAndCategories = useSelector((state) => state.CommonReducer.industriesAndCategories);
    const [selectedCategory, setSelectedCategory] = useState(3);
    const navigate = useNavigate();

    const handleTabClick = (industryId) => {
        setSelectedCategory(industryId);
    };

    const settings = {
        dots: false,
        arrows: true,
        centerMode: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <NextDark />,
        prevArrow: <PreviousDark />,
        infinite: true,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    infinite: true,
                    autoplay: false,
                    speed: 700,
                    autoplaySpeed: 500,
                    slidesToShow: 2,
                }
            },
        ]
    };
    return (
        <div className="home-explore-categories-section">
            <div className="container">
                <div className='row d-flex justify-content-between industries-main heading-bb'>
                    <div className='col-md-12 col-lg-5 col-xl-6 ps-0 industries-tabs'>
                        <HeadingDescription
                            headingText={headingText}
                            descriptionOne={descriptionOne}
                        />
                    </div>
                    <div className="col-md-12 pe-0 ps-0 industries text-end">
                        <div className='d-flex position-relative'>
                            <ul className='nav nav-tabs'>
                                {industriesAndCategories.map((item) => (
                                    <li
                                        key={`Industry-item-${item.Id}`}
                                        onClick={() => handleTabClick(item.Id)}
                                        className={selectedCategory === item.Id ? 'nav-link active-tab' : 'nav-link'}
                                    >
                                        {item.Name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="d-flex flex-wrap w-100 column-gap selling-products-slider position-relative">
                            <Slider {...settings}>
                                {industriesAndCategories.map((item, index) => (
                                    item.Categories.map((item2, Index2) => (
                                        selectedCategory === item.Id && (
                                            <div key={index} className="products">
                                                <img src={item2.Picture} alt={item2.Name} className="w-100" loading='lazy' />
                                                <h5>
                                                    {item2.Name}
                                                </h5>
                                                <Link to={item2.SeName} >
                                                    <h6>{linkText}</h6>
                                                </Link>
                                            </div>
                                        )
                                    ))
                                ))}
                            </Slider>
                            <div className="all-brands-resp">
                                <CustomButton text='VIEW ALL PRODUCTS' className='primary-btn' onClick={() => navigate('/product')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurCategories
