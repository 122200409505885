import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import LandingPagesMainTitle from "../../../Components/Zaraye/LandingPagesTitle";
import Input from "../../../Components/Common/input";
import SelectDropdown from "../../../Components/Common/selectDropdown";
import Button from "../../../Components/Common/CustomBtn";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import HandShake from "../../../Assests/Images/MarketPlace/hand-shake.png";
import { useSelector } from "react-redux";
import "./style.css";
import LocalizationContext from "../../../Utils/LocalizationContext";

const ContactUsPage = ({ item }) => {
  const allCountries = useSelector((state) => state.CommonReducer.countries);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [selectCountry, setSelectedCountry] = useState({});
  const [companyName, setCompanyName] = useState("");
  const { t } = useContext(LocalizationContext)

  const quoteFormDropdownStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderRadius: "8px", // Add border-radius
      border: "1px solid rgb(15 138 140 / 50%)",
      marginLeft: "-10px",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 0.5)",
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: "4px 8px",
    }),

    container: (provided, state) => ({
      ...provided,
      border: "1px solid rgb(15 138 140 / 50%)",
      borderRadius: "40px",
      padding: "0 10px",
      background: "#ffffff42",
      marginTop: "10px",
      fontSize: "13px",
      fontFamily: "Regular",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      background: "none",
      minHeight: "32px",
      border: "0",
      svg: {
        height: "17px",
        width: "17px",
      },
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#808080",
      border: "0",
      padding: "0",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "teal" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "teal",
        color: "white",
        // border: "2px solid",
      },
    }),
  };

  const handleSelectCountry = (selectCountry) => {
    setSelectedCountry(selectCountry);
  };

  const handleOnClickSubmitRequest = async () => {};

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta
          data-react-helmet="true"
          name="description"
          content={item?.MetaDescription ? item?.MetaDescription : ""}
        />
        <meta
          data-react-helmet="true"
          name="keywords"
          content={item?.MetaKeywords ? item?.MetaKeywords : ""}
        />
        <title>
          {item?.MetaTitle
            ? item?.MetaTitle
            : "Zaraye - B2B Raw Material Platform"}
        </title>
        {item?.Slug && (
          <link
            rel="canonical"
            href={`http://app-react.zaraye.co/${item?.Slug}`}
          />
        )}
      </Helmet>
      <section className="industry-landing-page">
        <Container>
          <div className="breadcrumb">
            <span className="pe-1 unactive">
              <Link to={"/"}>Home</Link>
            </span>
            <span className="pe-1 greater">
              <img src={ArrowLeft} width={14} alt="greater than" />
            </span>
            <span className="activelink">Contact Us</span>
          </div>
          <Row>
            <LandingPagesMainTitle
              headingText={t("zaraye.marketplace.contactus.main.title")}
              descriptionOne={t('zaraye.marketplace.contactus.main.description')}
            />
          </Row>
          <Row className="justify-content-between">
            <div className="col-md-4 pt-5 pb-4 order-last order-md-first">
              <h2 className="section-heading pb-3">
                {t('zaraye.marketplace.zarayecontactinformation.text')}
              </h2>
              <div className="contactus-left">
                <h2 className="section-inner-heading mb-1">{t('zaraye.marketplace.contactnumber.contact')}</h2>
                <span>+92 316 222 9990</span>
              </div>
              <div className="contactus-left">
                <h2 className="section-inner-heading mb-1">{t('zaraye.marketplace.zarayeemailaddress.email')}</h2>
                <span>Info@zaraye.co</span>
              </div>
              <div className="contactus-left">
                <h2 className="section-inner-heading mb-1">{t('zaraye.marketplace.zarayeheadoffice.text')}</h2>
                <span>
                  A 204, 2nd Floor, Fortune Towers, Shahrah-e-Faisal Rd, Block 6
                  - PECHS, Karachi, Sindh 75400
                </span>
              </div>
            </div>
            <div className="col-md-8 col-lg-7 col-xl-6 contactus">
              <div className="main-form">
                <h1 className="get-quote">{t('zaraye.marketplace.letsconnect.text')}</h1>
                <p className="mb-0">{t('zaraye.marketplace.letsconnect.description')}</p>
                <div className="row pt-4">
                  <div className="col-md-6 pb-2">
                    <div className="form-group">
                      <Input
                        className={"form-control"}
                        placeholder={"Enter Your Name"}
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        name={"name"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pb-2">
                    <div className="form-group">
                      <Input
                        className={"form-control"}
                        placeholder={"Phone Number"}
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                        name={"Phone Number"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pb-2">
                    <div className="form-group">
                      <Input
                        className={"form-control"}
                        placeholder={"Email Address"}
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        name={"Email Address"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pb-2">
                    <div className="form-group">
                      <SelectDropdown
                        data={allCountries}
                        optionValueProp="Value"
                        optionLabelProp="Text"
                        value={selectCountry?.Text}
                        onChange={handleSelectCountry}
                        styles={quoteFormDropdownStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 pb-2">
                    <div className="form-group">
                      <Input
                        className={"form-control"}
                        placeholder={"Enter Company Name"}
                        type="text"
                        name={"companyName"}
                        value={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 pb-2">
                    <div className="form-group">
                      <textarea
                        placeholder="Write a Message (If Any)"
                        name="message"
                        rows="4"
                        className="form-control w-100"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Button
                      className={"primary-btn w-100 text-center ripple"}
                      text="Submit"
                      onClick={handleOnClickSubmitRequest}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row className="contactus-footer align-items-center justify-content-between">
            <div className="col-md-6 solution-pg-title">
              <HeadingDescription
                headingText={t('zaraye.marketplace.ourcustomersareourpriority!.text')}
                descriptionOne={t('zaraye.marketplace.ourcustomersareourpriority!.description')}
              />
              <Button text={"Get Help Now"} className={"primary-btn mt-3"} />
            </div>
            <div className="col-md-5 justify-content-end contactus-priority order-first order-md-last">
              <img src={HandShake} alt={HandShake} className="img-fluid" />
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ContactUsPage;
