import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../../Pages/Global/Home';
import AboutPage from '../../Pages/Global/About';
import Header from '../../Components/Global/Header/index';

const GlobalRoutes = () => {
  return (
    <div className="main-layout zaraye-global-pg">
      <Header />
      <div className="inner-layout">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </div>
    </div>
  );
};

export default GlobalRoutes;
