import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import CommonReducer from '../Reducers/Common/CommonReducer';
import ShoppingCartReducer from '../Reducers/ShoppingCart/ShoppingCartReducer';
import MarketPlaceReducer from "../Reducers/MarketPlace/MarketPlaceReducer";

const appReducers = combineReducers({
    CommonReducer,
    ShoppingCartReducer,
    MarketPlaceReducer
});

const rootReducer = (state, action) => appReducers(state, action);

export const store = createStore(rootReducer, applyMiddleware(thunk));