import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './index.css'

const DropdownMenu = ({ menuTitle, options, className, onChangeDropdown }) => {

    const handleOnChangeDropDown = (id) => {
        if (onChangeDropdown) {
            onChangeDropdown(id)
        }
    }

    return (
        <Dropdown className='custom-dropdown'>
            <Dropdown.Toggle as="div" className={className}>
                {menuTitle}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {options.map((item, index) => {
                    return (
                        <Dropdown.Item onClick={() => handleOnChangeDropDown(item?.Id)} key={index} > <Link to={item?.Link}> {item?.Name} </Link> </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default DropdownMenu;
