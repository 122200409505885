import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './style.css'

const HeroCategoriesList = () => {

    const topCategoriesList = useSelector((state) => state.CommonReducer.allTopCategories);

    return (
        <>
            {topCategoriesList?.map((item, index) => (
                <div key={`top-category-item-${index}`}>
                    <Link to={`/${item.SeName}`} className='text-decoration-none'>
                        <div className='d-flex align-items-center ct-list'>
                            <div className="ct-list-img">
                                <img src={item.Picture} alt={item.Text} className='w-100'/>
                            </div>
                            <div className="ct-list-title ms-3">
                                <p className='mb-0'>{item.Text}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
        </>
    )
}

export default HeroCategoriesList
