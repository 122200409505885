import { useEffect, useState } from 'react';
import CommonService from '../../../Services/Common/CommonService';
import { Helmet } from "react-helmet";
import { Container, Row } from 'react-bootstrap';
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png"
import { Link } from "react-router-dom";
import './index.css'

const TopicDetailPage = ({ item }) => {

  const [topicData, setTopicData] = useState({});

  const getTopicDetail = async (id) => {

    var response = await CommonService.GetTopicById(id);
    if (response?.success) {
      setTopicData(response?.data)
    }
  }

  useEffect(() => {
    getTopicDetail(item?.Id);
  }, [])

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta data-react-helmet="true" name="description" content={item?.MetaDescription ? item?.MetaDescription : ''} />
        <meta data-react-helmet="true" name="keywords" content={item?.MetaKeywords ? item?.MetaKeywords : ''} />
        <title>{item?.MetaTitle ? item?.MetaTitle : 'Zaraye - B2B Raw Material Platform'}</title>
        {
          item?.Slug &&
          <link rel="canonical" href={`https://www.react-app.zaraye.co/${item?.Slug}`} />
        }
      </Helmet>
      <Container className='topic-detail-pg'>
        <div className="breadcrumb">
          <span className="pe-1 unactive">
            <Link to={"/"}>
                Home
            </Link>
          </span>
          <span className="pe-1 greater">
              <img src={ArrowLeft} width={14} alt="greater than" />
          </span>
          <span className="pe-1 unactive">
            <Link to={"/sitemap"}>
              Sitemap
            </Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" />
          </span>
          <span className="activelink pe-1">
            <Link to>
              {topicData?.Title}
            </Link>
          </span>
        </div>
        <Row>
          <div className='col-md-12'>
            <h2 className='text-center mb-4'> {topicData?.Title}</h2>
            <p dangerouslySetInnerHTML={{ __html: topicData?.Body }}></p>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default TopicDetailPage;
