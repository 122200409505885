import React from 'react'
import backDropRight from "../../../Assests/Images/Zaraye/landing-header-backdrop.png";
import backDropLeft from "../../../Assests/Images/Zaraye/landing-header-left-backdrop.png";
import './index.css'

const LandingPagesMainTitle = ({ headingText, descriptionOne }) => {
    return (
        <div className="container sub-header-container">
            <div className="header-back-drop-right d-none">
                <img src={backDropRight} alt={backDropRight} />
            </div>
            <div className="header-back-drop-left d-none">
                <img src={backDropLeft} alt={backDropLeft} />
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-9 col-lg-8 mx-auto">
                    <div className='landing-header-title'>
                        <div className="row">
                            <div className="col-sm-9 col-lg-8 col-xl-6 mx-auto">
                                <h1>{headingText}</h1>
                            </div>
                            <div className="col-sm-12 col-lg-10 col-xl-9 mx-auto">
                                <p className='mt-3 text-center'>{descriptionOne}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPagesMainTitle
