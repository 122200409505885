import React, { useState } from 'react'
import Slider from "react-slick";
import { ReactComponent as PreviousDark } from "../../../Assests/Images/Zaraye/previous.svg";
import { ReactComponent as NextDark } from "../../../Assests/Images/Zaraye/next.svg";
import HeadingDescription from '../SectionHeadingDescription';
import { Link } from 'react-router-dom';
import RequestAQuote from '../RequestModal';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../CustomBtn';
import './index.css'

const OurProducts = ({ data = [], headingText, descriptionOne, linkText }) => {
    const [modalShow, setModalShow] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const navigate = useNavigate();
    const slidesToShow = Math.min(data?.length, 5);

    const settings = {
        dots: false,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
        nextArrow: <NextDark />,
        prevArrow: <PreviousDark />,
        infinite: data?.length > slidesToShow,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                }
              },
            {
              breakpoint: 991,
              settings: {
                  slidesToShow: 3,
              }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    autoplay: false,
                }
            },
          ]
    };
    
    return (
        <div className="">
            <div className="container">
                <div className='row d-flex justify-content-between heading-bb'>
                    <div className='col-md-8 ps-0'>
                        <HeadingDescription
                            headingText={headingText}
                            descriptionOne={descriptionOne}
                        />
                    </div>
                    <div className="col-md-4 pe-0 text-end">
                        <Link to="" className='view-all resp-view-all'>
                            VIEW ALL PRODUCTS
                        </Link>
                    </div>
                </div>
                <div className="d-flex flex-wrap w-100 column-gap selling-products-slider">
                    <Slider {...settings}>
                        {data?.map((item, index) => (
                            <div key={index} className="card products p-0">
                                <Link to={`/${item?.SeName}`} className="text-decoration-none">
                                    <div className="position-relative prod-box-height">
                                        <div className="cat-thumbnail">
                                            <img src={item?.Picture} alt={item?.Name} className="w-100 cat-img-fix" loading='lazy' />
                                            <div className="overlay">
                                                <div className="buttons">
                                                    <CustomButton text='ADD TO CART' className='secondary-btn' onClick={() => navigate(`/${item.SeName}`)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <div className="card-body d-flex flex-column justify-content-between">
                                    <Link to={`/${item?.SeName}`} className="text-decoration-none">
                                        <h5 className="mb-0 mt-1">{item?.Name}</h5>
                                    </Link>
                                    <h6 
                                        className="mt-2" 
                                        onClick={() => {
                                            setSelectedProduct(item);
                                            setModalShow(true);
                                        }}
                                    >
                                       Explore Category
                                    </h6>
                                </div>
                            </div>
                        ))}
                    </Slider>
                    <RequestAQuote
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        productimage={selectedProduct?.Picture}
                        productname={selectedProduct?.Name}
                    />
                </div>
            </div>
        </div>
    )
}

export default OurProducts