import ZarayePeoples from "../../../Assests/Images/MarketPlace/zaraye-peoples.png";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import { ReactComponent as Previous } from "../../../Assests/Images/Zaraye/previous.svg";
import { ReactComponent as Next } from "../../../Assests/Images/Zaraye/next.svg";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import Life1 from "../../../Assests/Images/MarketPlace/life1.png";
import Life2 from "../../../Assests/Images/MarketPlace/life2.png";
import Life3 from "../../../Assests/Images/MarketPlace/life3.png";
import Life4 from "../../../Assests/Images/MarketPlace/life4.png";
import Life5 from "../../../Assests/Images/MarketPlace/life5.png";
import Life6 from "../../../Assests/Images/MarketPlace/life6.png";
import GroupResponsive from "../../../Assests/Images/MarketPlace/zaraye-groups-responsive-1.jpg";
import { Container, Row } from "react-bootstrap";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import { useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "./index.css";
import { useContext } from "react";
import LocalizationContext from "../../../Utils/LocalizationContext";

const CareerPage = ({ item }) => {
  const {t} = useContext(LocalizationContext)
  const allEmployees = useSelector(state => state.MarketPlaceReducer.employeeInsights);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    fade: true,
    className: "slick-slider-fade",
    cssEase: "linear",
    nextArrow: <Next />,
    prevArrow: <Previous />,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="careers-page">
        <Helmet defer={false}>
          <meta charSet="utf-8" />
          <meta
            data-react-helmet="true"
            name="description"
            content={item?.MetaDescription ? item?.MetaDescription : ""}
          />
          <meta
            data-react-helmet="true"
            name="keywords"
            content={item?.MetaKeywords ? item?.MetaKeywords : ""}
          />
          <title>
            {item?.MetaTitle
              ? item?.MetaTitle
              : "Zaraye - B2B Raw Material Platform"}
          </title>
          {item?.Slug && (
            <link
              rel="canonical"
              href={`http://app-react.zaraye.co/${item?.Slug}`}
            />
          )}
        </Helmet>
        <div className="career-pg">
          <Container>
            <div className="breadcrumb">
              <span className="pe-1 unactive">
                <Link to={"/"}>Home</Link>
              </span>
              <span className="pe-1 greater">
                <img src={ArrowLeft} width={14} alt="greater than" />
              </span>
              <span className="activelink">Careers</span>
            </div>
            <Row className="sub-header-container">
              <div className="col-md-10 col-xl-7 mx-auto landing-header-title">
                <h1>{t('zaraye.marketplace.career.main.title')}</h1>
              </div>
            </Row>
          </Container>
          <section className="redefining-sourcing-section">
            <Container>
              <Row className="align-items-center justify-content-between">
                <div className="col-lg-6 buildings-careers">
                  <h2 className="section-heading">
                    {t('zaraye.marketplace.buildingcareers.text')}
                  </h2>
                  <p className="section-description mt-3">{t('zaraye.marketplace.buildingcareers.description1')}</p>
                  <p className="section-description mb-0">{t('zaraye.marketplace.buildingcareers.description2')}</p>
                </div>
                <div className="col-lg-6 d-flex justify-content-end careers-peoples">
                  <img src={ZarayePeoples} alt={ZarayePeoples} loading="lazy" />
                </div>
              </Row>
            </Container>
          </section>
          <div className="brand-section">
            <Container>
              <Row>
                <div className="col-md-8 mx-auto text-center">
                  <HeadingDescription
                    headingText={t('zaraye.marketplace.employeeinsightszaraye.text')}
                    descriptionOne={t('zaraye.marketplace.employeeinsightszaraye.description')}
                  />
                </div>
              </Row>
            </Container>
            <div className="container life-of-zaraye">
              <Slider {...settings}>
                {allEmployees?.map((item, index) => (
                  <div key={`employee-insights-${index}`} className="row d-flex items">
                    <div className="col-lg-7 content-section">
                      <p>{item?.Description}</p>
                      <div className="d-flex flex-column content-naming">
                        <h5>{item?.Title}</h5>
                        <span>{item?.Designation}</span>
                      </div>
                    </div>
                    <div className="col-lg-5 image-section">
                      <img src={item?.Picture} alt={item?.Title} loading="lazy" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="careers-gallery">
            <Container>
              <Row>
                <div className="col-lg-9 col-xl-5 mx-auto text-center">
                  <HeadingDescription
                    headingText={t('zaraye.marketplace.visualodyssey.text')}
                    descriptionOne={t('zaraye.marketplace.visualodyssey.description')}
                  />
                </div>
              </Row>
              <div className="col-12 pt-5">
                <div className="row groups-img-xl">
                  <div className="col-lg-3 pe-0 d-flex my-auto px-0 ps-lg-4">
                    <img className="w-100 life-1" src={Life1} alt={Life1} loading="lazy" />
                  </div>
                  <div className="col-lg-6 my-auto">
                    <div className="row mb-4">
                      <div className="col-lg px-4">
                        <img className="w-100" src={Life2} alt={Life2} loading="lazy" />
                      </div>
                      <div className="col-lg-4 d-flex align-items-end px-4 ps-lg-0">
                        <img className="w-100" src={Life3} alt={Life3} loading="lazy" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 pe-0 px-4">
                        <img className="w-100" src={Life4} alt={Life4} loading="lazy" />
                      </div>
                      <div className="col-lg-8 px-4">
                        <img className="w-100" src={Life5} alt={Life5} loading="lazy" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 ps-0 my-auto px-4">
                    <img className="w-100 life-2" src={Life6} alt={Life6} loading="lazy" />
                  </div>
                </div>
                <div className="row groups-img">
                  <img className="w-100 life-1" src={GroupResponsive} alt="Zaraye" loading="lazy" />
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <GetZarayeApp />
    </>
  );
};

export default CareerPage;
