import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Success from "../../../Assests/Images/Zaraye/mp-success.svg";
import Danger from "../../../Assests/Images/Zaraye/mp-danger.svg";
import Button from "../CustomBtn";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import { useSelector } from "react-redux";
import "./style.css";

const RealTimePrice = (props) => {
  const commodityData = useSelector((state) => state.CommonReducer.commodityData);
  const { data } = props;
  const loadAllRates = data;
  const navigate = useNavigate();

  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        }
      }
    ]
  };

  return (
    <>
      <div className="d-flex justify-content-between live-slider position-relative align-items-center real-time-slider">
        <p className="mb-0 ps-3">Live</p>
        <div className="col-md-11 ps-0 ps-md-3 commodity">
          <Slider {...settings}>
            {commodityData.map((item, index) => (
              <div key={index} className="d-flex justify-content-between top-header">
                <div className="d-flex currency">
                  <span>{item?.Name}:</span>
                  <span className="ps-1 sm-ps-2">${item?.Rate}</span>
                </div>
                <div className="d-flex justify-content-between ms-2">
                  {parseFloat(item?.Percentage?.replace('%', '')) > 0 ? (
                    <img src={Success} alt="Success" />
                  ) : (
                    <img src={Danger} alt="Danger" />
                  )}
                  <span className={parseFloat(item?.Percentage.replace('%', '')) > 0 ? "green ms-1" : "red ms-1"}>
                    {item?.Percentage}
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="table-cont">
        <table className="table table-borderless real-time-price">
          <thead>
            <tr>
              <th className="first">Product</th>
              <th className="second">Industry</th>
              <th className="second">Updated</th>
              <th className="second">Rate</th>
              <th className="last-th"></th>
            </tr>
          </thead>
          <tbody>
            {loadAllRates?.map((item, index) => (
              <tr key={`load-all-rates-${index}`}>
                <td className="first">
                  {item?.product}
                  {
                    item?.ProductAttributes?.map((item, index) =>
                      " " + item?.Value + " "
                    )
                  }
                  {item?.brand}
                </td>
                <td className="second">
                  {item?.industry}
                </td>
                <td className="second">{item?.UpdateOn}</td>
                <td className="second">{item?.formatPrice}</td>
                <td className="last-td">
                  <Button
                    className="primary-btn view-product me-3"
                    text="VIEW PRODUCT"
                    onClick={() => navigate(`${item.SeName}`, {
                      state: {
                        ProductAttributesXML: item?.productattributesxml
                      }
                    })}
                  />
                  <Button
                    className="primary-btn chat-now"
                    text="WHATSAPP"

                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* responsive real time price divs */}
      <div className="real-time-insights-resp">
        {
          loadAllRates?.map((item, index) => (
            <div key={`load-all-resp-rates-${index}`} className="insights-items">
              <div className="d-flex justify-content-between align-items-center w-100">
                <span className="product-name">
                  <img src={item?.picture} alt={item?.industry} loading="lazy" />
                  {item?.product}
                  {
                    item?.ProductAttributes?.map((item, index) =>
                      " " + item?.Value + " "
                    )
                  }
                  {item?.brand}
                </span>
                <span>
                  {item?.industry}
                </span>
              </div>
              <div className="d-flex justify-content-between d-flex justify-content-between align-items-center w-100 py-1">
                <span>
                  <span className="updated pe-2">
                    Updated:
                  </span>
                  {item?.UpdateOn}
                </span>
                <span>
                  {item?.formatPrice}
                </span>
              </div>
              <div className="d-flex justify-content-end pt-2">
                <Button
                  className="primary-btn view-product me-3"
                  text="VIEW PRODUCT"
                  onClick={() => navigate(`${item.SeName}`)}

                />
                <Button
                  className="primary-btn chat-now"
                  text="WHATSAPP"

                />
              </div>
            </div>
          ))}
      </div>
      <Link className="text-decoration-underline uppercase all-products">View Full Price List {'>>'}</Link>
    </>
  );
};

export default RealTimePrice;
