import { Container } from "react-bootstrap";
import HeroIndustriesList from "../../../Components/Marketplace/HeroIndustriesList";

const CategoryPage = () => {
  return (
    <Container className="mp-hero-container">
      <div className="d-flex justify-content-between">
        <div className="hero-col-1 top-categories-colum">
          <h2>Top Industries</h2>
          <div className="categories-list">
            <HeroIndustriesList />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CategoryPage;
