//-------- Common Action Types -------------//
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const LOAD_INDUSTRIES = 'LOAD_INDUSTRIES';
export const LOAD_LANGUAGES = 'LOAD_LANGUAGES';
export const LOAD_INDUSTRIES_AND_CATEGORIES = 'LOAD_INDUSTRIES_AND_CATEGORIES';
export const LOAD_ALL_BRANDS = 'LOAD_ALL_BRANDS';
export const LOAD_LATEST_BLOGS = 'LOAD_LATEST_BLOGS';
export const LOAD_ALL_BLOGS = 'LOAD_ALL_BLOGS';
export const LOAD_ALL_SLUG = 'LOAD_ALL_SLUG';
export const LOAD_ALL_TOP_CATEGORIES = 'LOAD_ALL_TOP_CATEGORIES';
export const LOAD_ALL_PRODUCT_FILTER = 'LOAD_ALL_PRODUCT_FILTER';
export const LOAD_ALL_PRODUCTS = 'LOAD_ALL_PRODUCTS';
export const LOAD_ALL_COUNTRIES = 'LOAD_ALL_COUNTRIES';
export const LOAD_ALL_WEB_SLIDER = 'LOAD_ALL_WEB_SLIDER';
export const LOAD_ALL_RATES = 'LOAD_ALL_RATES';
export const LOAD_ALL_FAQS = 'LOAD_ALL_FAQS';
export const LOAD_ALL_BEST_SELLING_PRODUCTS = 'LOAD_ALL_BEST_SELLING_PRODUCTS';
export const LOAD_ALL_COMMODITY_DATA = 'LOAD_ALL_COMMODITY_DATA';
// export const LOAD_INDUSTRIES_DETAIL = 'LOAD_INDUSTRIES_DETAIL';

export const LOAD_SHOPPING_CART_ITEMS = 'LOAD_SHOPPING_CART_ITEMS';
export const LOAD_EMPLOYEE_INSIGHTS = 'LOAD_EMPLOYEE_INSIGHTS';
export const LOAD_MARKETPLACE_EXCHANGE_RATE = 'LOAD_MARKETPLACE_EXCHANGE_RATE';

export const LOAD_CUSTOMER_TESTIMONIAL_ITEMS = 'LOAD_CUSTOMER_TESTIMONIAL_ITEMS';

export const SAVE_LANGUAGE = 'SAVE_LANGUAGE';
export const SAVE_LANGUAGE_ID = 'SAVE_LANGUAGE_ID';