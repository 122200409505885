import React from "react";
import Slider from "react-slick";
import Success from "../../../Assests/Images/Zaraye/mp-success.svg";
import Danger from "../../../Assests/Images/Zaraye/mp-danger.svg";
import "./style.css";

const CurrencySlider = ({ data = [] }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
            slidesToShow: 4,
        }
      },
      {
        breakpoint: 991,
        settings: {
            slidesToShow: 3,
        }
      },
      // {
      //   breakpoint: 767,
      //   settings: {
      //       slidesToShow: 3,
      //   }
      // },
      {
        breakpoint: 490,
        settings: {
            slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <div className="currency-slider-container">
      <Slider {...settings}>
        {data.map((item, index) => (
          <div key={index} className="d-flex justify-content-between top-header">
            <div className="d-flex currency">
              <span>{item?.Symbol}:</span>
              <span className="ps-1 sm-ps-2">PKR {item?.Rate}</span>
            </div>
            <div className="d-flex justify-content-between ms-2">
              {item?.Percentage > 0 ? (
                <img src={Success} alt="Success" />
              ) : (
                <img src={Danger} alt="Danger" />
              )}
              <span className={`${item?.Percentage > 0 ? "green ms-1" : "red ms-1"}`}>
                {item?.Percentage}%
              </span>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CurrencySlider;
