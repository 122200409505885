import React, { useState } from 'react';
import './index.css';

const CustomCollapse = ({ title, data = [], onChangeFilter }) => {

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleCheckbox = (id, checked) => {
    if (onChangeFilter) {
      onChangeFilter(id, checked)
      console.log('ssaa');
    }
  };

  return (
    <div className={`collapse ${isCollapsed ? 'collapsed' : ''}`}>
      <button className="collapse-button" onClick={toggleCollapse}>
        {title}
      </button>
      <div className="collapse-content mt-3">
        {data?.map((item, index) => (
          <div className='mb-3 cursor-pointer' key={index}>
            <div className={`d-flex align-items-center ct-list ${item?.Checked ? 'selected-filter' : ''}`} onClick={() => toggleCheckbox(item?.Id, !item?.Checked)}>

                <input
                  type="checkbox"
                  id={`custom-checkbox-${index}`}
                  className="custom-checkbox"
                  checked={item?.Checked}
                  onChange={() => toggleCheckbox(item?.Id, !item?.Checked)}
                />
                <label
                  htmlFor={`custom-checkbox-${index}`}
                  className="custom-checkbox-label"
                ></label>
                <div className="ct-list-img">
                  <img src={item.Picture} className="w-100" />
                </div>
                <div className="ct-list-title">
                  <p className="mb-0" >
                    {item?.Name}
                  </p>
                </div>
              </div>
            </div>
        ))}
          </div>
    </div>
      );
};

      export default CustomCollapse;
