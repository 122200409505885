import React, { useState, useEffect } from "react";
import Input from "../input";
import SelectDropdown from "../selectDropdown";
import Button from "../CustomBtn";
import MarketPlaceService from "../../../Services/MarketPlace/MarketPlaceService";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import "./index.css";

const quoteFormDropdownStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "8px", // Add border-radius
    border: "1px solid rgb(15 138 140 / 50%)",
    marginLeft: "-10px",
    overflow: "hidden",
  }),

  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",

  }),

  placeholder: (provided) => ({
    ...provided,
    color: "rgba(0, 0, 0, 0.5)",
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: "0px 8px",
  }),

  container: (provided, state) => ({
    ...provided,
    border: "1px solid rgb(15 138 140 / 50%)",
    borderRadius: "40px",
    padding: "1.2px 10px",
    background: "#ffffff42",
    marginTop: "11px",
    fontSize: "13px",
    fontFamily: "Regular",
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    background: "none",
    minHeight: "32px",
    minHeight: "31px",
    padding: "0 ",
    border: "0",
    svg: {
      height: "17px",
      width: "17px",
    },
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#808080",
    border: "0",
    padding: "0",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "teal" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "teal",
      color: "white",
      // border: "2px solid",
    },
  }),
};

const countryPlaceholder = 'Country';
const unitPlaceholder = 'Units';

const GetQuoteForm = ({ formTitle, formDescription, source }) => {
  const allCountries = useSelector((state) => state.CommonReducer.countries);
  const [selectCountry, setSelectedCountry] = useState({});
  const [name, setName] = useState("");
  const [productName, setProductName] = useState("");
  const [quantity, setQuantity] = useState('');
  const [units, setUnits] = useState([]);
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const allUnits = [
    { value: 'Kilogram', label: 'Kilogram' },
    { value: 'Nos', label: 'Nos' },
    { value: 'Tons', label: 'Tons' },
    { value: 'Units', label: 'Units' },
    { value: "20 feet Container", label: "20' Container" },
    { value: "40 feet Container", label: "40' Container" },
    { value: 'Bags', label: 'Bags' },
    { value: 'Bag', label: 'Bag' },
    { value: 'Barrel', label: 'Barrel' },
    { value: 'Bottles', label: 'Bottles' },
    { value: 'Boxes', label: 'Boxes' },
    { value: 'Bushel', label: 'Bushel' },
    { value: 'Bushels', label: 'Bushels' },
    { value: 'Cartons', label: 'Cartons' },
    { value: 'Dozens', label: 'Dozens' },
    { value: 'Foot', label: 'Foot' },
    { value: 'Gallon', label: 'Gallon' },
    { value: 'Grams', label: 'Grams' },
    { value: 'Hectare', label: 'Hectare' },
    { value: 'Kilogram', label: 'Kilogram' },
    { value: 'Kilometer', label: 'Kilometer' },
    { value: 'Kilowatt', label: 'Kilowatt' },
    { value: 'Litre', label: 'Litre' },
    { value: 'Litres', label: 'Litres' },
    { value: 'Long Ton', label: 'Long Ton' },
    { value: 'Meter', label: 'Meter' },
    { value: 'Metric Ton', label: 'Metric Ton' },
    { value: 'Metric Tons', label: 'Metric Tons' },
    { value: 'Ounce', label: 'Ounce' },
    { value: 'Packets', label: 'Packets' },
    { value: 'Packs', label: 'Packs' },
    { value: 'Pair', label: 'Pair' },
    { value: 'Pairs', label: 'Pairs' },
    { value: 'Piece', label: 'Piece' },
    { value: 'Pieces', label: 'Pieces' },
    { value: 'Pound', label: 'Pound' },
    { value: 'Reams', label: 'Reams' },
    { value: 'Rolls', label: 'Rolls' },
    { value: 'Sets', label: 'Sets' },
    { value: 'Sheets', label: 'Sheets' },
    { value: 'Short Ton', label: 'Short Ton' },
    { value: 'Square Feet', label: 'Square Feet' },
    { value: 'Square Meters', label: 'Square Meters' },
    { value: 'Watt', label: 'Watt' },
  ]

  const handleUnitsChange = (units) => {
    setUnits(units)
  }

  const handleSelectCountry = (selectCountry) => {
    setSelectedCountry(selectCountry);
  };

  const handleOnClickSubmitRequest = async () => {

    if (name == "") {
      toast.error("Please Enter Your Name");
    } else if (productName == "") {
      toast.error("Please Enter Product Name");
    } else if (quantity == "") {
      toast.error("Please Enter Quantity");
    } else if (!units?.value) {
      toast.error("Please Select Units");
    } else if (city == "") {
      toast.error("Please Enter City Name");
    } else if (!selectCountry?.value) {
      toast.error("Please Select Country");
    } else if (phoneNumber == "") {
      toast.error("Please Enter Phone Number");
    } else if (email == "") {
      toast.error("Please Enter Your email");
    } else {
      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(email) === false) {
        toast.error("Please Enter valid email");
      } else {
        var payload = {
          name: name,
          service: productName,
          quantity: quantity,
          email: email,
          cityId: 0,
          unit: units?.value,
          cityName: city,
          contactNumber: phoneNumber,
          countryId: selectCountry?.value,
          source: source,
        };
        var result = await MarketPlaceService?.AddOnlineLead(payload);
        if (result?.success) {
          setSelectedCountry('');
          setName('');
          setProductName('');
          setQuantity('');
          setUnits('');
          setCity('');
          setEmail('');
          setPhoneNumber('');
          toast.success('Lead Successfully Generated');
        }
      }

    };

  }

  return (
    <div className="main-form">
      <h1 className="get-quote">{formTitle}</h1>
      <p className="mb-0">
        {formDescription}
      </p>
      <div className="row">
        <div className="form-group">
          <Input
            className={"form-control"}
            placeholder={"Enter Your Name"}
            type="text"
            value={name}
            maxLength={50}
            onChange={(e) => {
              if ((!e.target.value || /^[a-zA-Z\s]+$/.test(e.target.value))) {
                setName(e.target.value)
              }
            }}
            name={"name"}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group">
          <Input
            className={"form-control"}
            placeholder={"Enter Product Name"}
            type="text"
            maxLength={100}
            name={"productName"}
            value={productName}
            onChange={(e) => {
              setProductName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="d-flex form-6-colum flex-wrap justify-content-between">
        <div className="form-group">
          <Input
            className={"form-control"}
            placeholder={"Quantity"}
            type="number"
            maxLength={6}
            value={quantity}
            onChange={(e) => {
              const qtyRex = /^(\d+(\.\d{0,3})?|\.?\d{1,2})$/;
              if (e.target.value === '' || qtyRex.test(e.target.value)) {
                setQuantity(e.target.value);
              }
            }}
            name={"quantity"}
          />
        </div>
        <div className="form-group units">
          <SelectDropdown
            data={allUnits}
            value={units}
            menuIsOpen={true}
            onChange={handleUnitsChange}
            name={"units"}
            placeholder={unitPlaceholder}
            styles={quoteFormDropdownStyles}
          />
        </div>
        <div className="form-group">
          <Input
            className={"form-control"}
            placeholder={"City Name"}
            type="text"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
            name={"city"}
          />
        </div>
        <div className="form-group">
          <SelectDropdown
            data={allCountries}
            optionValueProp="Value"
            optionLabelProp="Text"
            value={selectCountry?.Text}
            onChange={handleSelectCountry}
            placeholder={countryPlaceholder}
            styles={quoteFormDropdownStyles}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <Input
              className={"form-control"}
              placeholder={"Phone Number"}
              type="tel"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              name={"Phone Number"}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <Input
              className={"form-control"}
              placeholder={"Email Address"}
              type="email"
              value={email}
              maxLength={50}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              name={"Email Address"}
            />
            {/* {!errorMessage && <p style={{ color: 'red' }}>Please enter a valid email.</p>} */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Button
            className={"primary-btn w-100 text-center ripple"}
            text="Get Your Quote Now"
            onClick={handleOnClickSubmitRequest}
          />
        </div>
      </div>
    </div>
  );
};

export default GetQuoteForm;
