import { Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import CommonService from '../../../Services/Common/CommonService';
import ratingStars from '../../../Assests/Images/MarketPlace/stars.svg';
import inStock from '../../../Assests/Images/MarketPlace/inStock.svg'
import increaseQty from '../../../Assests/Images/Common/increase.svg'
import decreaseQty from '../../../Assests/Images/Common/decrease.svg'
import CustomButton from "../../../Components/Common/CustomBtn";
import { Link, Route } from "react-router-dom";
import SelectDropdown from "../../../Components/Common/selectDropdown";
import Tabs from "../../../Components/Common/Tabs";
import OurProducts from "../../../Components/Common/Products";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png"
import ShopingCartService from '../../../Services/ShopingCart/ShopingCartService';
import ProductAttributes from "../../../Components/Common/ProductAttributes";
import { AttributeControlType } from "../../../Utils/Constant";
import * as shoppingCartAction from '../../../Actions/ShoppingCart/ShoppingCartAction';
import { toast } from 'react-toastify';
import './index.css'

const ProductDetailPage = ({ item }) => {

  const dispatch = useDispatch();
  const location = useLocation();

  const Categories = useSelector((state) => state.CommonReducer.industriesAndCategories);

  const [productData, setProductData] = useState({});
  const [productAttributesList, setProductAttributesList] = useState([]);
  const [productAttributesCondition, setProductAttributesCondition] = useState({});
  const [brandsData, setBrandsData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({});

  const getProductDetail = async (id) => {
    var response = await CommonService.GetProductById(id);
    if (response?.success) {
      setProductData(response?.data);
    }
  }

  const getProductAttributes = async (id) => {
    var response = await CommonService.GetProductAttributesById(id, location?.state?.ProductAttributesXML);
    if (response?.success) {
      setProductAttributesList(response?.data);
      await GetProductAttributesConditions(id);
    }
  }

  const GetProductAttributesConditions = async (id) => {
    let attributes = validateAttributes();
    var response = await CommonService.GetProductAttributeConditions(id, attributes.data);
    if (response.success) {
      setProductAttributesCondition(response.data);
    }
  }

  const getBrands = async (id) => {
    var response = await CommonService.GetBrandsByProductId(id);
    if (response?.success) {
      setBrandsData(response?.data);
    }
  }

  useEffect(() => {
    getProductDetail(item?.Id);
    getProductAttributes(item?.Id);
    getBrands(item?.Id);
  }, [item?.Id]);

  const navigate = useNavigate();

  const [value, setValue] = useState(1);

  const handleIncrement = () => {
    if (value < 99999) {
      setValue(value + 1);
    }
  };

  const handleDecrement = () => {
    if (value > 1) {
      setValue(value - 1);
    }
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue) && newValue >= 1 && newValue <= 99999) {
      setValue(newValue);
    }
  };

  const handleOnAttributeChange = async (data) => {
    setProductAttributesList(data);
    await GetProductAttributesConditions(item?.Id);
  }

  const handleOnChangeBrand = (value) => {
    setSelectedBrand(value);
  }

  const handleOnClickAddToCart = async () => {
    // navigate('/cart')
    let attributes = validateAttributes();
    if (!attributes?.success) {
      // alert(attributes.message)
      toast.error(attributes?.message);
    }
    else if (!selectedBrand?.value) {
      toast.error("Please Select Brand");
    }
    else {
      var payload = {
        "productId": item?.Id,
        "brandId": selectedBrand?.value,
        "quantity": value,
        "attributesData": attributes?.data
      }
      var result = await ShopingCartService.AddItemToCart(payload);
      if (result.success) {
        setProductAttributesList([]);
        dispatch(shoppingCartAction.fetchShoppingCartItems());
        getProductAttributes(item?.Id);
        setValue(1);
      } else {
        alert(result.message)
      }
    }
  }

  const validateAttributes = () => {
    let error = [], data = [];
    const itemList = productAttributesList.filter(x => !productAttributesCondition?.disabledattributemappingids?.includes(x.Id));
    for (let index = 0; index < itemList.length; index++) {
      const item = itemList[index];
      if (item.IsRequired) {
        if (item.AttributeControlType === AttributeControlType.TextBox) {
          if (item.DefaultValue === null || item.DefaultValue === "") {
            error.push(`Please Enter ${item.Name}`);
          }
          else {
            data.push({
              name: `product_attribute_${item.Id}`,
              value: item.DefaultValue
            });
          }
        }
        else if (item.AttributeControlType === AttributeControlType.NumericTextBox) {
          if (item.DefaultValue === null || item.DefaultValue === "") {
            error.push(`Please Enter ${item.Name}`);
          }
          else {
            data.push({
              name: `product_attribute_${item.Id}`,
              value: item.DefaultValue
            });
          }
        }
        else if (item.AttributeControlType === AttributeControlType.Dropdown) {
          let length = item.Values.filter(x => x.IsPreSelected === true).length;
          if (length === 0) {
            error.push(`Please Select ${item.Name}`);
          }
          item.Values.map((i) => {
            if (i.IsPreSelected) {
              data.push({
                name: `product_attribute_${item.Id}`,
                value: i.Id
              });
            }
          });
        }
        else if (item.AttributeControlType === AttributeControlType.RadioButton) {
          let length = item.Values.filter(x => x.IsPreSelected === true).length;
          if (length === 0) {
            error.push(`Please Select ${item.Name}`);
          }
          item.Values.map((i) => {
            if (i.IsPreSelected) {
              data.push({
                name: `product_attribute_${item.Id}`,
                value: i.Id
              });
            }
          });
        }
        else if (item.AttributeControlType === AttributeControlType.CheckBoxes) {
          let length = item.Values.filter(x => x.IsPreSelected === true).length;
          if (length === 0) {
            error.push(`Please Select ${item.Name}`);
          }
          item.Values.map((i) => {
            if (i.IsPreSelected) {
              data.push({
                name: `product_attribute_${item.Id}`,
                value: i.Id
              });
            }
          });
        }
      }
      else {
        if (item.AttributeControlType === AttributeControlType.Dropdown || item.AttributeControlType === AttributeControlType.RadioButton || item.AttributeControlType === AttributeControlType.CheckBoxes) {
          item.Values.map((i) => {
            if (i.IsPreSelected) {
              data.push({
                name: `product_attribute_${item.Id}`,
                value: i.Id
              });
            }
          });
        }
      }
    }
    return { success: error.length === 0, message: error[0], data: data };
  }

  return (
    <>
      <Container className="prod-detail-pg">
        <Helmet defer={false}>
          <meta charSet="utf-8" />
          <meta data-react-helmet="true" name="description" content={item?.MetaDescription ? item?.MetaDescription : ''} />
          <meta data-react-helmet="true" name="keywords" content={item?.MetaKeywords ? item?.MetaKeywords : ''} />
          <title>{item?.MetaTitle ? item?.MetaTitle : 'Zaraye - B2B Raw Material Platform'}</title>
          {
            item?.Slug &&
            <link rel="canonical" href={`https://www.react-app.zaraye.co/${item?.Slug}`} />
          }
        </Helmet>
        <div className="breadcrumb">
          <span className="pe-1 unactive">
            <Link to={"/"}>
              Home
            </Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" />
          </span>
          <span className="pe-1 unactive">
            <Link to={"/product"}>
              Products
            </Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" />
          </span>
          <span className="activelink">
            {productData?.Name}
          </span>
        </div>
        <Row className="align-items-center">
          <div className="col-lg-5 col-xl-6">
            <div className="prod-img select-prod-box-height">
              <img src={productData?.Picture} alt={productData?.Name} className="img-fluid prod-img-fix" />
            </div>
          </div>
          <div className="col-lg-7 col-xl-6 prod-detail-right">
            <div className="pd-category">
              <Row>
                <div className="col-md-12">
                  <p>Category:
                    <span className="pd-cat-name ms-2">
                      {productData?.Categories?.map((item, index) => item.Name).join(', ')}
                    </span>
                  </p>
                  <h2 className="pd-prod-title">{productData?.Name}</h2>
                  <div className="d-flex pd-reviews-row mb-3">
                    <div className="pd-star">
                      <img src={ratingStars} alt={ratingStars} />
                    </div>
                    <div className="pd-reviews px-2">
                      <p className="mb-0">({productData?.ProductReviews?.length} Reviews)</p>
                    </div>
                    <div className="pd-ask-question d-flex">
                      <p className="mb-0"> Ask a Question? </p>
                    </div>
                    <div className="in-stock d-flex align-items-center px-2">
                      <img src={inStock} alt={inStock} />
                      <p className="mb-0 ms-2"> In Stock</p>
                    </div>
                  </div>
                  <div className="pd-description">
                    <p>
                      {productData?.ShorDescription}
                    </p>
                  </div>
                </div>
              </Row>
              <ProductAttributes
                data={productAttributesList}
                onAttributeChange={handleOnAttributeChange}
                disabledattributeId={productAttributesCondition?.disabledattributemappingids}
              />
              <Row className="mt-2">
                <div className="col-md-8 form-options mb-3">
                  <SelectDropdown
                    optionValueProp="Value"
                    optionLabelProp="Text"
                    data={brandsData}
                    label={'Brands'}
                    onChange={handleOnChangeBrand}
                  />
                </div>
              </Row>
              <Row className="mt-2">
                <div className="col-md-10 form-options">
                  <div className="d-flex form-item align-items-center">
                    {productData?.UnitName ? (
                      <p className="mb-0">Quantity ({productData.UnitName}):</p>
                    ) : (
                      <p className="mb-0">Quantity:</p>
                    )}
                    <div className="qtyField position-relative mx-3">
                      <input
                        className="w-100"
                        type="number"
                        value={value}
                        min="1"
                        max="99999"
                        onChange={handleChange}
                      />
                      <div className="decrease-qty" onClick={handleDecrement}><img src={decreaseQty} alt={decreaseQty} /> </div>
                      <div className="increase-qty" onClick={handleIncrement}><img src={increaseQty} alt={increaseQty} /></div>
                    </div>
                  </div>
                </div>
              </Row>
              <Row className="mt-4">
                <div className="col-md-12 d-flex align-items-center prod-detail-btn">
                  <CustomButton text={'ADD TO CART'} className={'primary-btn me-3'} onClick={handleOnClickAddToCart} />
                  <p className="mb-0 text-dark"><Link className="text-dark">Call Us Now For Quick Quote</Link></p>
                </div>
              </Row>
            </div>
          </div>
        </Row>
        {productData?.LongDescription?.length > 0 ?
          (<Row className="product-description-section">
            <div className="col-md-12">
              <Tabs tabs={
                [
                  {
                    label: 'Product Description',
                    content: productData?.LongDescription
                  }
                  // {
                  //   label: 'Additional Information',
                  //   content: 'Content for Tab 2'
                  // }
                ]
              } />
            </div>
          </Row>
          ) : ''}
      </Container>
      <div className="mp-home">
        <Container className="also-like-section position-relative our-customer-section">
          {/* <Row> */}
            <OurProducts data={Categories} headingText={'You May Also Like'} />
          {/* </Row> */}
        </Container>
      </div>
      <div className="mt-5">
        <GetZarayeApp />
      </div>
    </>
  );
}

export default ProductDetailPage;
